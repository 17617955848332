import React, { useEffect, useState } from "react";
import { images } from "../../assets/images";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../features/auth/slice/user.slice";
import { getUser } from "../../features/auth/slice/user.selector";
import { EChatType } from "../../features/chatmessages/enum/type.enum";
import { routes } from "../../router/routes";
import { notificationService } from "../../services/notification.service";
import { INotification } from "../../interfaces/notification.interface";
import { DynamicLoader } from "../../utils/DynamicLoader";
import { IPaginationInfo } from "../../interfaces/pagination-info.interface";
import { Toast } from "../../utils/Toast";
import moment from "moment";
import {
  ENotificationType,
  EWhich,
} from "../../interfaces/notification-type.enum";
import Swal from "sweetalert2";
import { SideChatComponent } from "../SideChat/SideChat";
import AsyncSelect from 'react-select/async';
import { userService } from "../../services/user.service";
import { eventService } from "../../services/events.service";
import { IEvent } from "../../features/organisations/createorganisation/interfaces/event.interface";
import { ShowEventModal } from "../../features/themecomponents/ShowEventModal";
import {IUnreadMessage} from "../../interfaces/unread-message.interface";
import {FullPageLoader} from "../../utils/FullPageLoader";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {NoActionNeededModal} from "../../features/ownorganisation/components/NoActionNeededModal";
import {IPost} from "../../features/organisations/createorganisation/interfaces/post.interface";
import {postService} from "../../services/post.service";
import {CreateScheduledPostModal} from "../../features/ownorganisation/components/CreateScheduledPostModal";
import {useTranslation} from "react-i18next";
import {ChangeLanguageModal} from "../changeLanguage/ChangeLanguage";
import {_io} from "../../services/socket.service";
import InfiniteScroll from "react-infinite-scroll-component";

const updateSearchSchema = yup.object().shape({
  search: yup.string()
}).required();

let totalUnreadCount: any = {};
let globalUnreadMessages: any = [];
export const AppLayout: React.FC = (props) => {
  const [sideBarToggle, setSideBarToggle] = useState(false);
  const [rightSideChatToggle, setRightSideChatToggle] = useState(false);
  const [dynamicLoader, setDynamicLoader] = useState(false);
  const [messagesLoader, setMessagesLoader] = useState(false);
  const [fullWidthContent, setFullWidthContent] = useState(false);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [unreadMessages, setUnreadMessages] = useState<IUnreadMessage[]>([]);
  const [paginationInfoUnreadMessages, setPaginationInfoUnreadMessages] =
    useState<IPaginationInfo>({
      currentPage: 0,
      pages: 0,
      perPage: 0,
      totalRecords: 0,
    });
  const [paginationInfoNotifications, setPaginationInfoNotifications] =
    useState<IPaginationInfo>({
      currentPage: 0,
      pages: 0,
      perPage: 0,
      totalRecords: 0,
    });
  const {register, handleSubmit} = useForm({
    resolver: yupResolver(updateSearchSchema)
  });
  const dispatch = useDispatch();
  const [openChangeLanguageModal, setOpenChangeLanguageModal] = useState(false);
  const handleClose = () => setOpenChangeLanguageModal(false);
  const handleShow = () => setOpenChangeLanguageModal(true);
  const [selectedEvent, setSelectedEvent] = useState<IEvent>();
  const [showMemberDetailModal, setShowMemberDetailModal] = useState<boolean>(false);
  const [searchType, setSearchType] = useState('users');
  const [isLoading, setIsloading] = useState(false)
  const [post, setPost] = useState<IPost>();
  const [alreadyApprovedModal, setAlreadyApprovedModal] = useState(false);
  const [createScheduledPostModal, setCreateScheduledPostModal] = useState(false);
  const history = useHistory();
  const currentUser = useSelector(getUser);
  const { t } = useTranslation('', { keyPrefix: 'NavBar' });

  useEffect(() => {
    getUnreadCount();
    getUnreadMessages(true, 1);
    if (!history.location.pathname.includes('all-chat')) {
      chatCountSocketHandler()
    }

    return () => {
      _io.off(`new-message-notification-${currentUser._id}`);
    };
  }, []);
  useEffect(() => {
    if (history.location.pathname.includes('all-chat')) {
      setFullWidthContent(true);
    } else {
      setFullWidthContent(false);
    }
  }, []);


  const chatCountSocketHandler = () => {
    _io.on(`new-message-notification-${currentUser._id}`, (data: any) => {
      handleSocketResponse(data)
    });
  };

  const handleSocketResponse = (data: any) => {
    if (data.type === "MESSAGE_REACTION") {
      Toast.fire({
        icon: "info",
        title: `${data.messageReaction.sender.name} reacted ${data.messageReaction.reaction} to ${data.message.content != '' ? data.message.content : 'media'}`,
      });
    } else {
      setPaginationInfoUnreadMessages({
        currentPage: totalUnreadCount.currentPage,
        pages: totalUnreadCount.currentPage,
        perPage: totalUnreadCount.perPage,
        totalRecords: totalUnreadCount.totalRecords + 1
      })
      totalUnreadCount = {
        currentPage: totalUnreadCount.currentPage,
        pages: totalUnreadCount.currentPage,
        perPage: totalUnreadCount.perPage,
        totalRecords: totalUnreadCount.totalRecords + 1
      }
      setUnreadMessages([data.message,...globalUnreadMessages!])
      globalUnreadMessages = [data.message,...globalUnreadMessages!]
      Toast.fire({
        icon: "info",
        title: `${data.message.sender.name} sent a new message: ${data.message.content != '' ? data.message.content : 'media'}`,
      });
    }
  }
  const getNotifications = async (e: boolean) => {
    if (e) {
      setDynamicLoader(true);
      const res: any = await notificationService.getNotifications(1, 3);
      if (res.statusCode == 200) {
        setPaginationInfoNotifications(
          res.payload.notifications.paginationInfo
        );
        setNotifications(res.payload.notifications.records);
        markAsReadNotifications();
      } else {
        Toast.fire({
          icon: "warning",
          title: res.message,
        });
      }
      setDynamicLoader(false);
    }
  };
  const getUnreadMessages = async (e: boolean, page: number) => {
    if (e) {
      setMessagesLoader(true);
      const res: any = await notificationService.getUnreadMessages(page, 100);
      if (res.statusCode == 200) {
        if (page == 1) {
          setUnreadMessages([...res.payload.messages.records])
          globalUnreadMessages = [...res.payload.messages.records]
        } else {
          setUnreadMessages([...unreadMessages, ...res.payload.messages.records])
          globalUnreadMessages = [...unreadMessages, ...res.payload.messages.records]
        }
        setPaginationInfoUnreadMessages(res.payload.messages.paginationInfo)
        totalUnreadCount = res.payload.messages.paginationInfo;
      } else {
        Toast.fire({
          icon: "warning",
          title: res.message,
        });
      }
      setMessagesLoader(false);
    }
  };
  const getUnreadCount = async () => {
    const res: any = await notificationService.getNotificationUnreadCount();
    if (res.statusCode == 200) {
      setUnreadCount(res.payload.count);
    } else {
      Toast.fire({
        icon: "warning",
        title: res.message,
      });
    }
  };
  const markAsReadNotifications = async () => {
    const res: any = await notificationService.markAsReadNotification();
    if (res.statusCode == 200) {
      setUnreadCount(0);
    } else {
      Toast.fire({
        icon: "warning",
        title: res.message,
      });
    }
  };
  const openConfirmationDialog = async (noti: any) => {
    history.push(`/organization-profile/${noti.kind?._id}`);
    // Swal.fire({
    //   title: "Accept Invite",
    //   text: "",
    //   icon: "question",
    //   showCancelButton: true,
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "#d33",
    //   confirmButtonText: "Accept",
    //   cancelButtonText: "Reject",
    // }).then(async (result: any) => {
    //   if (result) {
    //     if (result.isConfirmed) {
    //       await updateRequestedFollowStatus("ACCEPTED", noti);
    //     } else {
    //       await updateRequestedFollowStatus("REJECTED", noti);
    //     }
    //   }
    // });
  };
  const goToAllNotifications = async () => {
    await markAsReadNotifications();
    history.push("/all-notifications");
  };
  const handleToggle = () => {
    setRightSideChatToggle(!rightSideChatToggle);
  };
  const openOrganization = (noti: INotification, endpoint: string) => {
    switch (endpoint) {
      case "requested":
        history.push({
          pathname: `/organization-profile/${noti.kind?._id}`,
          search: "requested",
        });
        break;
      case "members":
        history.push({
          pathname: `/organization-profile/${noti.kind?._id}`,
          search: "members",
        });
        break;

      default:
        break;
    }
  };

  const onChangeSelectedOption = (e: any) => {
    if (searchType == 'users') {
      history.push(`/memberprofilepage/${e.value}`)
    }
    if (history.location.pathname.includes('member')) {
      window.location.reload();
    }
  };
  const promiseOptions = async (inputValue: string) => {
    if (inputValue.length > 1) {
      if (searchType == 'users') {
        const res: any = await userService.globalUserSearch(inputValue)
        if (res.statusCode == 200) {
          const tempArray: any[] = [];
          for (let i = 0; i < res.payload.users.records.length; i++) {
            tempArray.push({
              label: res.payload.users.records[i].name,
              value: res.payload.users.records[i]._id
            })
          }
          return tempArray;
        } else {
          Toast.fire({
            icon: 'warning',
            title: res.message
          })
        }
      }
    }
  }

  const searchUpdates = (event: any) => {
      if (searchType == 'updates')
      {
        history.push(`/post-search?search=${event.search}`)
      }
  }
  const openEventDetail = async (id: string) => {
    setDynamicLoader(true);
    const res: any = await eventService.getEventDetail(id);
    setDynamicLoader(false);
    res.payload.event.isExpanded = true;
    setSelectedEvent(res.payload.event)
    setShowMemberDetailModal(true)
  }
  const closeEventDetailModal = () => {
    setShowMemberDetailModal(false)
  }
  const openChat = (chat: IUnreadMessage) => {
    if (chat.chat.type === EChatType.ONE_TO_ONE) {
      history.push(
          `${routes.allChatPage}?tab=${EChatType.ONE_TO_ONE}&active=${chat?.chat?._id}`
      )
    } else if (chat.chat.type === EChatType.GROUP) {
      history.push(
          `${routes.allChatPage}?tab=${EChatType.GROUP}&active=${chat?.chat?._id}&groupId=${chat.chat?.group?._id}`
      )
    }
  }
  const markAllAsRead = async (e:any) => {
    setMessagesLoader(true);
    const res: any = await notificationService.markAllAsRead();
    if (res.statusCode == 200) {
      Toast.fire({
        icon: "success",
        title: res.message,
      });
      getUnreadMessages(true, 1)
    } else {
      Toast.fire({
        icon: "warning",
        title: res.message,
      });
    }
    setMessagesLoader(false);
  }

  const getPostId = (meta:any): string => {
    return JSON.parse(meta)?.post
  }
  const getEventId = (meta:any): string => {
    return JSON.parse(meta)?.event
  }
  const getRejectionReason = (meta:any): string => {
    return JSON.parse(meta)?.reason
  }
  const deleteAccount = () => {
    Swal.fire({
      title: t('deleteAccount'),
      text: t('sureDeleteAccount'),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t('confirm'),
      cancelButtonText: t('reject'),
    }).then(async (result: any) => {
      if (result && result.isConfirmed) {
        setDynamicLoader(true)
        const res: any = await userService.deleteAccount();
        setDynamicLoader(false);
        if (res.statusCode == 200) {
          Toast.fire({
            icon: "success",
            title: res.message,
          });
          dispatch(Actions.setLogout());
          history.push('/');
        } else {
          Toast.fire({
            icon: "warning",
            title: res.statusCode == 406 ? t('UnableDeleteAccount') : res.message,
          });
        }
      }
    });
  }

  const getExtraMessage = (meta: any) => {
    const message = JSON.parse(meta)?.personalPushNotificationMessage
    if (message)
    {
      return ': ' + message;
    }
  }

  const searchTypeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
      setSearchType(e.target.value);
  }
  const openScheduleModal = async (postId: string) => {
    setIsloading(true)
    const res: any = await postService.getPostById(postId);
    if (res.statusCode == 200) {
      setIsloading(false)
      if (res.payload.post.isRequested === 'YES' && !res.payload.post?.deletedAt) {
        setPost(res.payload.post);
        setCreateScheduledPostModal(true)
      }else if(res.payload.post.isRequested === 'NO' || res.payload.post?.deletedAt){
        setAlreadyApprovedModal(true)
      }
    } else {
      setIsloading(false)
      Toast.fire({
        icon: 'warning',
        title: res.message
      })
    }
  }
  const handleCloseSchedule = () => {
    setCreateScheduledPostModal(false)
  }
  const closeNoActionModal = () => {
    setAlreadyApprovedModal(false)
  }
  return (
    <div
      className={`up-wrapper ${sideBarToggle ? "up-openclose-sidebar-menu" : ""
        } ${rightSideChatToggle ? "up-opensidebarchat" : ""} ${fullWidthContent ? "up-fullwidthcontent" : ""}`}
    >
      <header id="up-header" className="up-header">
        <div className="up-headercontent">
          <span className="up-menuiconholder">
            <button
              onClick={() => setSideBarToggle(!sideBarToggle)}
              className="up-btnmenuicon"
              type="button"
            >
              <img src={images.MenuIcon} alt="Menu Icon" />
            </button>
          </span>
          <strong className="up-logo">
            <Link to="/home">
              <img src={images.Logo} alt="Logo Image" />
            </Link>
          </strong>
          <div className="up-globalsearch">
            <form className="up-formtheme up-formglobalsearch" onSubmit={handleSubmit(searchUpdates)}>
              <fieldset>
                <div className="form-group up-inputwithselect">
                  {searchType == 'updates' && <input
                      type="text"
                      {...register("search")}
                      className="form-control"
                      placeholder={t('searchUpdates')}
                  />}
                  <div className='up-select up-selectsearch'>
                    <select className="up-selecttheme" value={searchType} onChange={(e) => searchTypeHandler(e)}>
                      <option value="users">{t('users')}</option>
                      <option value="updates">{t('updates')}</option>
                    </select>
                  </div>
                  {searchType == 'users' && <AsyncSelect className="up-async-select" placeholder={t('searchUsers')} onChange={onChangeSelectedOption} cacheOptions defaultOptions loadOptions={promiseOptions} />}
                  <button className="up-btnsearch" type="button">
                    <i className="icon-search"></i>
                  </button>
                </div>
              </fieldset>
            </form>
          </div>
          <div className="up-headerrightarea">
            <ul className="up-messgnotificationicons">
               <li>
                <Dropdown onToggle={(e) => getUnreadMessages(e, 1)}
                    className="up-themedropdown up-notificationdropdown">
                  <Dropdown.Toggle id="dropdown-basic">
                    <img src={images.MessageIcon} alt="Message Icon" />
                    {paginationInfoUnreadMessages?.totalRecords > 0 && (
                        <span style={{ color: "#000" }}>{paginationInfoUnreadMessages.totalRecords}</span>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="up-themedropdownmenu">
                    <div className="up-messagesnotihead">
                      <h3>{t('message')}</h3>
                      {paginationInfoUnreadMessages?.totalRecords > 0 && <button type="button" className="up-btnmarkasread" onClick={markAllAsRead}>
                        {t('markAllAsRead')}
                      </button>}
                    </div>
                    {messagesLoader && <DynamicLoader />}
                    <ul className="up-messagesnotificationsdropdown">
                      {unreadMessages && unreadMessages?.length > 0 &&
                          <InfiniteScroll
                              dataLength={unreadMessages!.length} //This is important field to render the next data
                              next={() => getUnreadMessages(true,(paginationInfoUnreadMessages?.currentPage! + 1))}
                              hasMore={unreadMessages!.length !== paginationInfoUnreadMessages?.totalRecords!}
                              loader={<div className="text-center">{t('loading')}</div>}
                              endMessage={
                                <p className="up-allpostseen" style={{ textAlign: 'center' }}>
                                  <b>{t('noMoreMessages')}</b>
                                </p>
                              }>
                            {unreadMessages?.map((item:IUnreadMessage, index: number) => <li>
                              <div className="up-messagesnotification" onClick={e => openChat(item)} style={{cursor:'pointer'}}>
                                <figure className="up-usernotiimg">
                                  <img src={item?.sender?.avatar ?? images.UserOne} alt="User Image" />
                                </figure>
                                <div className="up-notificationcontent">
                                  <h4>
                                    {item?.sender?.name}
                                    <time dateTime="2022-12-12">{moment(item?.createdAt).fromNow()}</time>
                                  </h4>
                                  <div className="up-description">
                                    <p>
                                      {item.content != '' ? item.content : 'media'}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>)}
                          </InfiniteScroll>}
                      {unreadMessages?.length == 0 && <div className="text-center">{t('noNewMessages')}</div>}
                    </ul>
                    {/*<a href="/all-chat" className="up-btn">*/}
                    {/*  {t('viewAllMessage')}*/}
                    {/*</a>*/}
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li>
                {/* <a href="javascript:void(0);">
                                    <img src={images.NotificationIcon} alt="Message Icon"/>
                                </a> */}
                <Dropdown
                  onToggle={(e: any) => getNotifications(e)}
                  className="up-themedropdown up-notificationdropdown"
                >
                  <Dropdown.Toggle id="dropdown-basic">
                    <img src={images.NotificationIcon} alt="Message Icon" />
                    {unreadCount > 0 && (
                      <span style={{ color: "#000" }}>{unreadCount}</span>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="up-themedropdownmenu">
                    <div className="up-messagesnotihead">
                      <h3>{t('newUpdates')}</h3>
                      {/* <button type="button" className="up-btnmarkasread">Mark all as Read</button> */}
                    </div>
                    {dynamicLoader && <FullPageLoader />}
                    <ul className="up-messagesnotificationsdropdown">
                      {notifications.length > 0 &&
                        notifications.map((noti) => (
                          <li>
                            <div className="up-messagesnotification">
                              <figure className="up-usernotiimg">
                                <img
                                  src={
                                    noti.sender.avatar ?? images.UserPlaceholder
                                  }
                                  alt="User Image"
                                />
                              </figure>
                              <div className="up-notificationcontent">
                                {noti.which === EWhich.Organization && (
                                  <>
                                    <h4>
                                      {noti.kind.name}{" "}
                                      <time dateTime="2022-12-12">
                                        {moment(noti.createdAt).fromNow()}
                                      </time>
                                    </h4>
                                    <div className="up-description">
                                      {noti.type ===
                                        ENotificationType.POST_REQUEST_APPROVED && (
                                          <p style={{ cursor: 'pointer' }}>
                                            {noti.sender.name} {t('requestApproved')}
                                          </p>
                                        )}
                                      {noti.type ===
                                        ENotificationType.NEW_POST_REQUESTED_ADDED && (
                                          <p onClick={() => openScheduleModal(getPostId(noti?.metaData))} style={{ cursor: 'pointer' }}>
                                            {noti.sender.name} {t('requestAnUpdate')}
                                          </p>
                                        )}
                                      {noti.type ===
                                        ENotificationType.POST_REQUEST_REJECTED && (
                                          <p style={{ cursor: 'pointer' }}>
                                            {noti.sender.name} {t('requestRejected')}{getRejectionReason(noti?.metaData) ?  ': ' + getRejectionReason(noti?.metaData) : ''}
                                          </p>
                                        )}
                                      {noti.type ===
                                        ENotificationType.ORGANIZATION_FOLLOW_REQUEST && (
                                          <p style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                              openOrganization(noti, "requested")
                                            }
                                          >
                                            {noti.sender.name} {t('requestToFollow')}
                                          </p>
                                        )}
                                      {noti.type ===
                                        ENotificationType.ORGANIZATION_FOLLOW_REQUEST_RESPONSE && (
                                          <a style={{ cursor: 'pointer' }}
                                            href={`/organization-profile/${noti.kind?._id}`}
                                          >
                                            <p>
                                              {noti.sender.name} {t('requestAccepted')}
                                            </p>
                                          </a>
                                        )}
                                      {noti.type ===
                                        ENotificationType.ORGANIZATION_FOLLOW_REQUEST_REJECTED && (
                                          <a style={{ cursor: 'pointer' }}
                                            href={`/organization-profile/${noti.kind?._id}`}
                                          >
                                            <p>
                                              {noti.sender.name} {t('hasRejectedRequest')} {getRejectionReason(noti?.metaData) ? t('reason') + getRejectionReason(noti?.metaData) : ''}
                                            </p>
                                          </a>
                                        )}
                                      {noti.type ===
                                        ENotificationType.ORGANIZATION_MEMBER_INVITE && (
                                          <p style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                              openConfirmationDialog(noti)
                                            }
                                          >
                                            {noti.sender.name} {t('invitedYouTo')}{noti.kind.name}.
                                          </p>
                                        )}
                                      {noti.type ===
                                        ENotificationType.NEW_POST_ADDED && (
                                          <a style={{ cursor: 'pointer' }}
                                             href={`/post-detail/${getPostId(noti?.metaData)}`}
                                          >
                                            {noti.sender.name} {t('hasPostedNewUpdate')} {noti.kind.name} {getExtraMessage(noti?.metaData)}
                                          </a>
                                        )}
                                      {noti.type ===
                                        ENotificationType.POST_UPDATED && (
                                          <a style={{ cursor: 'pointer' }}
                                             href={`/post-detail/${getPostId(noti?.metaData)}`}> {t('anUpdateIn')} {noti?.kind?.name} {t('hasBeenEdited')}
                                          </a>
                                        )}
                                      {noti.type ===
                                        ENotificationType.POST_LIKED && (
                                          <a style={{ cursor: 'pointer' }}
                                             href={`/post-detail/${getPostId(noti?.metaData)}`}
                                          >
                                            {noti?.sender?.name} {t('hasLikedYourUpdate')} {noti?.kind?.name}
                                          </a>
                                        )}
                                      {noti.type ===
                                        ENotificationType.SHARE_POST_IN_ORGANIZATION && (
                                          <a style={{ cursor: 'pointer' }}
                                             href={`/post-detail/${getPostId(noti?.metaData)}`}
                                          >
                                            {noti?.sender?.name} {t('hasSharedYourUpdate')} {noti?.kind?.name}
                                          </a>
                                        )}
                                      {noti.type ===
                                        ENotificationType.NEW_EVENT_ADDED && (
                                          <p style={{ cursor: 'pointer' }} onClick={() => openEventDetail(getEventId(noti?.metaData))}>
                                            {noti.sender.name} {t('hasAddedEvent')} {noti.kind.name}
                                          </p>
                                        )}
                                      {noti.type ===
                                        ENotificationType.EVENT_UPDATED && (
                                          <p style={{ cursor: 'pointer' }} onClick={() => openEventDetail(getEventId(noti?.metaData))}>
                                            {t('anEventIn')} {" " + noti.kind.name + " "} {t('hasBeenEdited')}
                                          </p>
                                        )}
                                    </div>
                                  </>
                                )}
                                {noti.which === EWhich.Post && (
                                  <>
                                    <h4>
                                      {noti.kind.content.length > 10 ? noti.kind.content.slice(0,10).trim() + '...': noti.kind.content}{" "}
                                      <time dateTime="2022-12-12">
                                        {moment(noti.createdAt).fromNow()}
                                      </time>
                                    </h4>
                                    <div className="up-description">
                                      {noti.type ===
                                        ENotificationType.COMMENT_ADDED && noti.kind.creator !== noti.sender._id && (
                                          <a style={{ cursor: 'pointer' }}
                                            href={`/post-detail/${noti.kind?._id}`}
                                          >
                                            <p>
                                              {noti.sender.name} {t('commentAdded')}
                                            </p>
                                          </a>
                                        )}
                                      {noti.type ===
                                        ENotificationType.COMMENT_ADDED && noti.kind.creator == noti.sender._id && (
                                          <a style={{ cursor: 'pointer' }}
                                            href={`/post-detail/${noti.kind?._id}`}
                                          >
                                            <p>
                                              {noti.sender.name} {t('commentAddedImportant')}
                                            </p>
                                          </a>
                                        )}
                                    </div>
                                  </>
                                )}
                                {noti.which === EWhich.Group && (
                                  <>
                                    <h4>
                                      {noti.kind.name}{" "}
                                      <time dateTime="2022-12-12">
                                        {moment(noti.createdAt).fromNow()}
                                      </time>
                                    </h4>
                                    <div className="up-description">
                                      {noti.type ===
                                        ENotificationType.GROUP_MEMBER_ADDED && (
                                          <p style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                              openOrganization(noti, "members")
                                            }
                                          >
                                            {noti.sender.name} {t('addedMemberGroup')}
                                          </p>
                                        )}
                                    </div>
                                  </>
                                )}
                                {noti.which === EWhich.Event && <>
                                  <h4 style={{ cursor: 'pointer' }}>{noti.kind.name} {" "}
                                      <time dateTime="2022-12-12">
                                        {moment(noti.createdAt).fromNow()}
                                      </time></h4>
                                  <div className="up-description" style={{ cursor: 'pointer' }}>
                                    {noti.type === ENotificationType.EVENT_REMAINDER && <p onClick={() => openEventDetail(noti.kind._id)}>{Math.abs(moment(noti.createdAt).diff(moment(noti.kind.startDate), 'hours'))} {t('hoursUntil')} {noti.kind.name} {t('starts')}</p>}
                                  </div></>}
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                    {alreadyApprovedModal && <NoActionNeededModal closeModal={closeNoActionModal} />}
                    {createScheduledPostModal && <CreateScheduledPostModal id={post?.organization?._id ?? ''} showSelectionButtons={true} selectedEditPost={post!} isEditPost={true} closeModal={handleCloseSchedule} />}
                    {showMemberDetailModal && <ShowEventModal editEvent={selectedEvent!} closeModal={closeEventDetailModal} />}
                    {paginationInfoNotifications.totalRecords > 3 && (
                      <span
                        onClick={() => goToAllNotifications()}
                        className="up-btn"
                        style={{ cursor: 'pointer' }}
                      >
                        {t('viewAllNotifications')}
                      </span>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
            <Dropdown className="up-themedropdown up-userdropdown">
              <Dropdown.Toggle id="dropdown-basic">
                <figure className="up-userimage">
                  {/* <span className="up-userimgtag">
                                        <img src={images.UserImageTag} alt="User Tag" />
                                    </span> */}
                  <img
                    src={currentUser?.avatar ?? images.UserPlaceholder}
                    alt="User Profile Image"
                  />
                </figure>
                <div className="up-userprofilecontent">
                  <h3>{currentUser.name}</h3>
                  <i className="icon-menu-icon"></i>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="up-themedropdownmenu">
                <Dropdown.Item href="/profile-page">
                  <span>{t('profile')}</span>
                </Dropdown.Item>
                <Dropdown.Item href="/create-organisation">
                  <span>{t('createOrganization')}</span>
                </Dropdown.Item>
                <Dropdown.Item href="/privacy-settings?search=general">
                  <span>{t('privacySettings')}</span>
                </Dropdown.Item>
                <Dropdown.Item href="termsandconditions" target="_blank">
                  <span>{t('termsAndConditions')}</span>
                </Dropdown.Item>
                <Dropdown.Item href="javascript:void(0);" onClick={handleShow}>
                  <span>{t('language')}</span>
                </Dropdown.Item>
                <Dropdown.Item onClick={deleteAccount}>
                  <span style={{color:'red'}}>{t('deleteAccount')}</span>
                </Dropdown.Item>
                <Dropdown.Item
                  href="/"
                  onClick={() => dispatch(Actions.setLogout())}
                >
                  <span>{t('logout')}</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="up-sidebarwrapper">
          <nav className="navigation">
            <ul className="nav_list">
              <li className="list-item" data-tooltip={t('updatesFeed')}>
                <a href="/home">
                  <i className="icon-newsfeed"></i>
                  <em>{t('updatesFeed')}</em>
                  <span className="text">{t('updatesFeed')}</span>
                </a>
              </li>
              <li className="list-item" data-tooltip={t('organisations')}>
                <a href="/all-organizations">
                  <i className="icon-users"></i>
                  <em>{t('organisations')}</em>
                  <span className="text">{t('organisations')}</span>
                </a>
              </li>
              <li className="list-item" data-tooltip={t('files')}>
                <a href="/all-files">
                  <i className="icon-file"></i>
                  <em>{t('files')}</em>
                  <span className="text">{t('files')}</span>
                </a>
              </li>
              <li className="list-item" data-tooltip={t('events')}>
                <a href="/events">
                  <i className="icon-calendar"></i>
                  <em>{t('events')}</em>
                  <span className="text">{t('events')}</span>
                </a>
              </li>
              <li className="list-item" data-tooltip={t('userGroups')}>
                <a href="/users-groups">
                  <i className="icon-groupchat"></i>
                  <em>{t('userGroups')}</em>
                  <span className="text">{t('userGroups')}</span>
                </a>
              </li>
              <li className="list-item" data-tooltip={t('chats')}>
                <a
                  href=""
                  onClick={() => {
                    history.push(
                      `${routes.allChatPage}?tab=${EChatType.ONE_TO_ONE}`
                    );
                  }}
                >
                  <i className="icon-singlechat"></i>
                  <em>{t('chats')}</em>
                  <span className="text">{t('chats')}</span>
                </a>
              </li>
              <li className="list-item" data-tooltip={t('shortCuts')}>
                <a
                  href=""
                  onClick={() => {
                    history.push(
                      `${routes.shortCutsPage}`
                    );
                  }}
                >
                  <i className="icon-share"></i>
                  <em>{t('shortCuts')}</em>
                  <span className="text">{t('shortCuts')}</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <main className="up-main">{props.children}</main>
      {/* <div className="up-chatwrapper">
                <button onClick={() => setRightSideChatToggle(!rightSideChatToggle)} className="up-btnchat" type="button">
                    <i className="icon-singlechat"></i>
                </button>
                <div className="up-onlinememberholder">
                    <ul className="up-chatusers">
                        <li>
                            <figure onClick={() => setChatToggle(!chatToggle)} className="up-chatusersimg">
                                <span className="up-onlinedot up-offlinecolor"></span>
                                <img src={images.UserOne} alt="User Image" />
                            </figure>
                        </li>
                        <li>
                            <figure className="up-chatusersimg">
                                <span className="up-onlinedot up-onlinecolor"></span>
                                <img src={images.UserTwo} alt="User Image" />
                            </figure>
                        </li>
                        <li>
                            <figure className="up-chatusersimg">
                                <span className="up-onlinedot up-offlinecolor"></span>
                                <img src={images.UserThree} alt="User Image" />
                            </figure>
                        </li>
                        <li>
                            <figure className="up-chatusersimg">
                                <span className="up-onlinedot up-offlinecolor"></span>
                                <img src={images.UserFour} alt="User Image" />
                            </figure>
                        </li>
                        <li>
                            <figure className="up-chatusersimg">
                                <span className="up-onlinedot up-onlinecolor"></span>
                                <img src={images.UserFive} alt="User Image" />
                            </figure>
                        </li>
                        <li>
                            <figure className="up-chatusersimg">
                                <span className="up-onlinedot up-onlinecolor"></span>
                                <img src={images.UserSix} alt="User Image" />
                                <button className="up-btnaddtofriendlist" type="button"><i className="icon-plus"></i></button>
                            </figure>
                        </li>
                        <li>
                            <figure className="up-chatusersimg up-onlinecolor">
                                <span className="up-onlinedot"></span>
                                <img src={images.UserSeven} alt="User Image" />
                            </figure>
                        </li>
                    </ul>
                </div>
            </div> */}
      <SideChatComponent handleToggle={handleToggle} />
      {/* Change Lanugage Modal Start */}

      {openChangeLanguageModal && <ChangeLanguageModal closeModal={handleClose}/>}
    </div>
  );
};
