import {yupResolver} from "@hookform/resolvers/yup";
import moment from "moment";
import {useEffect, useState} from "react";
import {Dropdown} from "react-bootstrap";
import {useForm} from "react-hook-form";
import Lightbox from "react-image-lightbox";
import {useSelector} from "react-redux";
import {images} from "../../assets/images";
import {getUser} from "../../features/auth/slice/user.selector";
import {
    IOrganizationRequestedMembers
} from "../../features/organisations/createorganisation/interfaces/organization-requested-members.interface";
import {
    ICreatePostComment,
    IPostComment
} from "../../features/organisations/createorganisation/interfaces/post-comment.interface";
import {IPollOption, IPostComp} from "../../features/organisations/createorganisation/interfaces/post.interface";
import {
    dateSurveySchema,
    postCommentSchema,
    textSurveySchema
} from "../../features/organisations/createorganisation/validations/post-comment.validation";
import {EPolicy} from "../../interfaces/organization-policies.enums";
import {IPaginationInfo} from "../../interfaces/pagination-info.interface";
import {organisationService} from "../../services/organisation.service";
import {postService} from "../../services/post.service";
import {Toast} from "../../utils/Toast";
import "react-image-lightbox/style.css";
import {URLReplacer} from "../../utils/url-replacer";
import {FullPageLoader} from "../../utils/FullPageLoader";
import {useInView} from "react-intersection-observer";
import {EPostCategory} from "../../interfaces/post-category.interface";
import {useHistory} from "react-router-dom";
import {EBoolean} from "../../features/auth/pages/signup/interfaces/drop-down.enums";
import {EPollResultType, EPollType} from "../../interfaces/post-type.interface";
import {VotingDetailModal} from "../VotingDetails/VotingDetailModal";
import {FacebookShareButton} from "react-share";
import {UserDetailModal} from "../user-detail-modal/UserDetailModal";
import {Mention, MentionsInput} from "react-mentions";
import {constructContent, constructMessage} from "../../utils/ConstructMessage";
import mentionsInputStyle from "./mentionsInputStyle";
import {DisplayToDetailModal} from "../displayToDetailModal/DisplayToDetailModal";
import PinIcon from "../../assets/images/pin.svg"
import {useTranslation} from "react-i18next";

export const OrganizationPost: React.FC<IPostComp> = (props) => {

    const [postComments, setPostComments] = useState<IPostComment[]>()
    const [myRole, setMyRole] = useState<IOrganizationRequestedMembers>({
        isBlocked: "NO",
        member: {
            _id: 'string',
            name: 'string',
            username: 'string',
            email: 'string'
        },
        organization: "",
        role: {
            _id: 'string',
            role: 'string',
            policies: [],
        },
        status: "",
        _id: "",
        isMember: false,
        _v: 0
    });
    const [isOpen, setIsOpen] = useState(false);
    const [votingDetail, setVotingDetail] = useState(false);
    const [likeDetails, setLikeDetails] = useState(false);
    const [displayToDetail, setDisplayToDetail] = useState(false);
    const [viewDetails, setViewDetails] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [postImages, setPostImages] = useState<any[]>([])
    const [pollOption, setPollOption] = useState<IPollOption[]>(props.post.pollOptions ?? [])
    const [votedOption, setVotedOption] = useState<IPollOption>()
    const [multiOptions, setMultiOptions] = useState<IPollOption[]>([])
    const [voteCount, setVoteCount] = useState<number>(props.post.votesCount ?? 0)
    const [alreadyVoted, setAlreadyVoted] = useState<boolean>(false)
    const [commentsPaginationInfo, setCommentsPaginationInfo] = useState<IPaginationInfo>()
    const [isLoading, setIsloading] = useState(false)
    const [isViewed, setIsViewed] = useState(false)
    const [pollTime, setPollTime] = useState<number>(0)
    const [pollClosed, setPollClosed] = useState(moment().isBefore(moment(props.post.pollEndsAt)))
    const [tags, setTags] = useState<any>([])
    const [content, setContent] =useState("");
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const currentUser = useSelector(getUser);
    
    const history = useHistory();
    const isPublic = props.public
    const isLoggedIn = Object.keys(currentUser).length > 1
    const { ref, inView } = useInView({
        threshold: 0
    });
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(postCommentSchema)
    });
    const { register: textRegister, handleSubmit: textSubmit, reset: textRest, formState: { errors: textErrors } } = useForm({
        resolver: yupResolver(textSurveySchema)
    });
    const { register: dateRegister, handleSubmit: dateSubmit, reset: dateReset, formState: { errors: dateErrors } } = useForm({
        resolver: yupResolver(dateSurveySchema)
    });
    const { t } = useTranslation('', { keyPrefix: 'OrganizationPost' });
    useEffect(() => {
        if (props.post.mediaType === 'IMAGE') {
            for (const iterator of props.post.medias!) {
                if (iterator.toString().includes('postImage')) {
                    postImages.push(iterator);
                }
            }
            setPostImages([...postImages]);
        }
        if (!isPublic && isLoggedIn) {
            let tempID = props?.post?.organization?._id! ?? props?.post?.organization;
            if (tempID.length > 0) {
                getMyOrganizationRole(tempID);
            }
        }
    }, [])

    useEffect(() => {
        if (moment().isSameOrBefore(moment(props.post.pollEndsAt))) {
            const duration = moment.duration(moment().diff(moment(props.post.pollEndsAt)));
            setPollTime(Math.round(Math.abs(duration.asMinutes())));
        } else {
            setPollTime(0);
        }
    }, [])

    useEffect(() => {
        if (!isPublic && isLoggedIn) {
        if (inView && !isViewed) {
            readPost();
        }
        }
    }, [inView])
    useEffect(() => {
        if (props.post.isPollMultiSelect === EBoolean.YES) {
            pollOption?.map((option, index) => {
                option.voters?.map((voter, voterIndex) => {
                    if (voter.votedBy._id === currentUser._id) {
                        setAlreadyVoted(true)
                        multiOptions.push(option);
                    }
                })
            })
            setMultiOptions([...multiOptions])
        } else {
            pollOption.map((option, index) => {
                option?.voters?.map((voter, voterIndex) => {
                    if (voter.votedBy._id === currentUser._id) {
                        setAlreadyVoted(true)
                        setVotedOption(option);
                    }
                })
            })
        }

    }, [setPollOption])

    const getMyOrganizationRole = async (orgId: string) => {
        const res: any = await organisationService.getMyOrganizationRole(orgId);
        if (res.statusCode == 200) {
            if (res.payload.organizationMember && res.payload.organizationMember.status == "ACCEPTED") {
                res.payload.organizationMember.isMember = true;
                if (res.payload.organizationMember.role.policies.includes(EPolicy.CAN_VIEW_COMMENTS)) {
                    getPostComments(props.post._id, 1)
                }
                setMyRole(res.payload.organizationMember)
            }
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const openImages = (index: number) => {
        setPhotoIndex(index);
        setIsOpen(true);
    }
    const likePost = async () => {
        let status = '';
        if (!props?.post?.likes?.includes(currentUser._id)) {
            status = 'YES';
        } else {
            status = 'NO';
        }
        setIsloading(true);
        const res: any = await postService.likePost({ postId: props.post._id, status: status, addLog: true });
        setIsloading(false);
        if (res.statusCode == 200) {
            props.setLikePost(props.post._id, currentUser._id, status)
            Toast.fire({
                icon: 'success',
                title: res.message
            })
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const getPostComments = async (id: string, page: number) => {
        const res: any = await postService.getPostComments(id, page)
        if (res.statusCode == 200) {
            setCommentsPaginationInfo(res.payload.comments.paginationInfo)
            if (postComments) {
                setPostComments([ ...postComments,...res.payload.comments.records])
            } else {
                setPostComments([...res.payload.comments.records])
            }
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const readPost = async () => {
        const res: any = await postService.readPost(props.post._id)
        if (res.statusCode == 200) {
            setIsViewed(true);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const onSubmit = async (data: ICreatePostComment) => {
        setIsSubmit(true)
        data.post = props.post._id;

        data.addLog = true;
        const res: any = await postService.createPostComment({...data, content:  constructContent(data.content),tags: tags.map((tag:any) => tag._id),})
        if (res.statusCode == 201) {
            const temp: any = [];
            temp.push(res.payload.comment);
            // reset({ content: '' })
            setContent("")
            if (postComments) {
                commentsPaginationInfo!.totalRecords! = 1 + commentsPaginationInfo!.totalRecords!;
                setCommentsPaginationInfo(commentsPaginationInfo!)
                setPostComments([...temp, ...postComments]);
            } else {
                commentsPaginationInfo!.totalRecords! = 1 + commentsPaginationInfo!.totalRecords!;
                setCommentsPaginationInfo(commentsPaginationInfo!)
                setPostComments([...temp]);
            }
            setIsSubmit(false)
        } else {
            setIsSubmit(false)
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    };
    const voteOption = async (event: any, option: any, index: number) => {
        if (event?.target?.checked) {
            if (moment().isBefore(moment(props.post.pollEndsAt))) {
                if (alreadyVoted === false && votedOption === undefined) {
                    callVoteAPI(option, index, true);
                } else if (votedOption && alreadyVoted === true) {
                    setIsloading(true);
                    const res: any = await postService.removePollVote({
                        "postId": props.post._id,
                        "optionId": votedOption._id
                    });
                    if (res.statusCode === 200) {
                        const previousVoteIndex = pollOption.findIndex(item => item._id === votedOption._id)
                        if (previousVoteIndex !== -1) {
                            const voterIndex = pollOption[previousVoteIndex].voters.findIndex(ele => ele.votedBy._id === currentUser._id);
                            if (voterIndex !== -1) {
                                pollOption[previousVoteIndex].voters.splice(voterIndex, 1)
                                setPollOption([...pollOption])
                            }
                        }
                        callVoteAPI(option, index, false);
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: res.message
                        })
                    }
                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: t('cannotVoteMultipleTimes')
                    })
                }
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: t('pollIsClosed')
                })
            }
        }
    }

    const callVoteAPI = async (option: any, index: number, increaseCount: boolean) => {
        setIsloading(true);
        const res: any = await postService.castPollVote({
            "postId": props.post._id,
            "optionId": option._id,
            addLog: true
        });
        setIsloading(false);
        if (res.statusCode === 200) {
            setAlreadyVoted(true)
            setVotedOption(option);
            if (increaseCount) {
                setVoteCount(voteCount + 1);
            }
            pollOption[index].voters.push({
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                votedBy: {
                    _id: currentUser._id,
                    name: currentUser.name,
                    username: currentUser.username,
                }
            });
            setPollOption([...pollOption])
            Toast.fire({
                icon: 'success',
                title: res.message
            })
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }

    }
    const textOnSubmit = async (data: any) => {
        setIsloading(true);
        const res: any = await postService.addCustomPoll({
            "postId": props.post._id,
            "option": data.optionValue,
            addLog: true
        });
        setIsloading(false);
        if (res.statusCode === 200) {
            textRest()
            res.payload.option.voters.push({
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                votedBy: {
                    _id: currentUser._id,
                    name: currentUser.name,
                    username: currentUser.username,
                }
            });
            setVoteCount(voteCount + 1);
            multiOptions.push(res.payload.option);
            setMultiOptions([...multiOptions]);
            pollOption.push(res.payload.option);
            setAlreadyVoted(true)
            setPollOption([...pollOption])
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const dateOnSubmit = async (data: any) => {
        setIsloading(true);
        const res: any = await postService.addCustomPoll({
            "postId": props.post._id,
            "option": data.date + " " + data.startTime + " - " + data.endTime,
            addLog: true
        });
        setIsloading(false);
        if (res.statusCode === 200) {
            dateReset()
            res.payload.option.voters.push({
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                votedBy: {
                    _id: currentUser._id,
                    name: currentUser.name,
                    username: currentUser.username,
                }
            });
            setVoteCount(voteCount + 1);
            multiOptions.push(res.payload.option);
            setMultiOptions([...multiOptions]);
            pollOption.push(res.payload.option);
            setAlreadyVoted(true)
            setPollOption([...pollOption])
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const closeVotingDetailModal = () => {
        setVotingDetail(false)
    };
    const closeLikeDetailModal = () => {
        setLikeDetails(false)
    };
    const closeDisplayToDetailModal = () => {
        setDisplayToDetail(false)
    };
    const closeViewDetailModal = () => {
        setViewDetails(false)
    };
    const handlePollSelection = async (event: any, option: any, index: number) => {
        if (event.target.checked) {
            setIsloading(true);
            const res: any = await postService.castPollVote({
                "postId": props.post._id,
                "optionId": option._id,
                addLog: true
            });
            setIsloading(false);
            if (res.statusCode === 200) {
                setAlreadyVoted(true)
                setVoteCount(voteCount + 1);
                multiOptions.push(option)
                setMultiOptions([...multiOptions]);
                pollOption[index].voters.push({
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString(),
                    votedBy: {
                        _id: currentUser._id,
                        name: currentUser.name,
                        username: currentUser.username,
                    }
                });
                setPollOption([...pollOption])
                Toast.fire({
                    icon: 'success',
                    title: res.message
                })
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: res.message
                })
            }
        } else {
            setIsloading(true);
            const res: any = await postService.removePollVote({
                "postId": props.post._id,
                "optionId": option._id
            });
            setIsloading(false);
            if (res.statusCode === 200) {
                setVoteCount(voteCount - 1);
                const multiIndex = multiOptions.findIndex(item => item._id === option._id);
                if (multiIndex !== -1) {
                    multiOptions.splice(multiIndex, 1);
                    if (multiOptions.length === 0) {
                        setAlreadyVoted(false)
                    }
                    setMultiOptions([...multiOptions]);
                }
                const voterIndex = pollOption[index].voters.findIndex(ele => ele.votedBy._id === currentUser._id);
                if (voterIndex !== -1) {
                    pollOption[index].voters.splice(voterIndex, 1)
                    setPollOption([...pollOption])
                }
                Toast.fire({
                    icon: 'success',
                    title: res.message
                })
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: res.message
                })
            }
        }
    };
    const openProfile = (id: string) => {
        if (!id){
            return
        }
        if (currentUser._id === id) {
            history.push(`/profile-page`)
        } else {
            history.push(`/memberprofilepage/${id}`)
        }
    }
    const openOrganization = () => {
        history.push(`/organization-profile/${props.post.organization?._id}`)
    }
    const deleteComment = async (comment: IPostComment, index: number) => {
        setIsloading(true);
        const res: any = await postService.deletePostComment(comment._id)
        setIsloading(false);
        if (res.statusCode === 200) {
            if (postComments) {
                postComments.splice(index,1)
                setPostComments([...postComments])
            }
            Toast.fire({
                icon: 'success',
                title: res.message
            })
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const pleaseLogin = () =>{
        Toast.fire({
            icon: 'warning',
            title: t('pleaseLogin')
        })
    }

    const markPostAsSeen = async () => {
        setIsloading(true);
        const res: any = await postService.markPostAsSeen(props.post._id)
        setIsloading(false);
        if (res.statusCode == 200) {
            if (props.removeFromList)
            {
                props.removeFromList(props.post._id)
            }
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const viewMentionDataHandler =async (e: string, callback:any) => {
        console.log(e)
        const tempArray: any[] = [];
        if (e.length > 1) {
            
            const res: any = await organisationService.getOrganizationMembers(props.post.organization!._id!, 1, e, 10, "");
            if (res.statusCode == 200) {
                
                for (let i = 0; i < res.payload.organizationMembers.records.length; i++) {
                    tempArray.push({
                        display: res.payload.organizationMembers.records[i].member.name,
                        id: res.payload.organizationMembers.records[i].member._id
                    })
                }
                
                
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: res.message
                })
            }
        }
        
        
        return callback(tempArray)
      };
    
      const messageOnChangeHandler =(e: any) => {
        const value = e.target.value;
        const regex = /\@\[([^\]]+)\]\(([^)]+)\)/g;
        const matches = [...value.matchAll(regex)];
        const mentions = matches.map(match => {
          const [_, display, _id] = match;
          return { _id, name: display };
        });
           let uniqueTags:any = new Map();
          [...mentions].forEach((item) => {
            uniqueTags.set(item._id, item);
          })
          setTags([...uniqueTags.values()]);
        
        setContent(e.target.value)
      }

      const openDisplayToDetail = (e: any) => {
          e.preventDefault();
          e.stopPropagation();
          if (props.post.displayTo!.length > 0)
          {
              setDisplayToDetail(true);
          }
      }
    const getDisplayToName = (displayTo: any[]) => {
        return displayTo.map(item => item?.name || item?.role).toString()
    }
    return <div className="up-post">
        {isLoading && <FullPageLoader />}
        <div className="up-posthead">
            <div className="up-postnameuserimage">
                <figure className="up-userimage">
                    {!props.showInOrganization && <img src={props.post.organization?.avatar ?? images.UserPlaceholder}  onClick={openOrganization} alt="User Image" />}
                    {props.showInOrganization && <img src={props.post.creator?.avatar ?? images.UserPlaceholder} alt="User Image" />}
                </figure>
                <div className="up-postheadcontent">
                    {props.showInOrganization && <h3 style={{ fontWeight: 'bold', cursor: 'pointer', color: '#000', float: 'left', }} onClick={() => openProfile(props.post.creator._id)}>{props.post.creator.name} {(props.post.isContributorVisible === 'YES' && props.post.contributor?.name) && <span onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        openProfile(props.post.contributor?._id ?? '')
                    }}>{t('updateContributionBy')} {props.post.contributor?.name}</span>}</h3>}
                    {!props.showInOrganization && <h3 style={{ fontWeight: 'bold', cursor: 'pointer', color: '#000', float: 'left', }} >
                        <span style={{ fontWeight: 'bold', cursor: 'pointer', color: '#000', display: 'block' }} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            openOrganization()
                        }}>{props.post.organization?.name}</span>
                        <span>{t('postedBy')} <span style={{ fontWeight: 'bold', cursor: 'pointer', color: '#000', display: 'contents' }} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            openProfile(props.post.creator._id)
                        }}> {props.post.creator.name}</span>{(props.post.isContributorVisible === 'YES' && props.post.contributor?.name) && <span style={{ fontWeight: 'bold', cursor: 'pointer', color: '#000', display: 'contents' }} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            openProfile(props.post.contributor?._id ?? '')
                        }}>, {props.post.contributor?.name}</span>}</span>
                    </h3>}

                    <div className="up-publicdatearea"><span><span onClick={openDisplayToDetail}>{props.post.displayTo!.length > 0 ? getDisplayToName(props.post.displayTo) : t('public') }</span> {moment(props.post.updatedAt).fromNow()}</span></div>
                </div>
                {/* <span className="up-markasreadpin"> <img src={PinIcon} /></span> */}
                {props.post?.isPinned === 'YES' && !props.post.markedAsSeen?.includes(currentUser._id) &&  <span className="up-markasreadpin" onClick={markPostAsSeen}> <img src={PinIcon} /></span>}
                {isLoggedIn && <Dropdown className="up-themedropdown">
                    <Dropdown.Toggle id="dropdown-basic">
                        <i className="icon-menu-icon"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="up-themedropdownmenu">
                        {((currentUser._id == props.post.creator._id) || props.fromAdmin) && <Dropdown.Item onClick={() => props.setEditPost(props.post)} >{t('edit')}</Dropdown.Item>}
                        {((currentUser._id == props.post.creator._id) || (myRole.isMember && myRole.role.policies.includes(EPolicy.CAN_DELETE_POSTS)) || props.fromAdmin) && <Dropdown.Item onClick={() => props.setDeletePost(props.post)} >{t('deletePost')}</Dropdown.Item>}
                        {!props.fromTimeline && <Dropdown.Item href="javascript:void(0);" >{t('pinToTop')}</Dropdown.Item>}
                        {currentUser._id !== props.post.creator._id && <Dropdown.Item onClick={() => props.reportPost(props.post)} >{t('report')}</Dropdown.Item>}
                    </Dropdown.Menu>
                </Dropdown>}
            </div>
        </div>
        <div ref={ref} className="up-postdescription">
            <p dangerouslySetInnerHTML={{ __html: URLReplacer(props.post.content) }}></p>
        </div>
        <div className={`up-postsimagearea ${props?.post?.medias?.length == 1 ? 'up-singleimage' : 'up-twoimages'}`}>
            <div className="up-allfilesholder">
            </div>
            {props.post.mediaType === 'FILE' && props.post.medias && props.post.medias?.map((item: any, index: number) => {
                return <>
                    {item.toString().includes('postFile') &&
                        <div className="up-fileiconparent">
                            <a href={item} key={item} target="_blank" rel="noreferrer" className="up-postfileuploaded">
                                <em>
                                    <i className="icon-file" />
                                </em>
                                <span>{item.split('postFile')[1]?.replace('-','')?.replace(/%20/g, " ")}</span>
                            </a>
                        </div>
                    }
                </>
            })}

            {props.post.mediaType === 'IMAGE' && props.post.medias && props.post.medias?.map((item: any, index: number) => {
                return <>
                    {item.toString().includes('postVideo') && <video key={item} width="666" controls>
                        <source src={item} /> </video>}
                </>
            })}
            {props.post.mediaType === 'IMAGE' && postImages?.map((img, index) => (<figure onClick={() => openImages(index)} key={img} className="up-postimage">
                <img src={img} alt="up-postimage" />
            </figure>))}
            {isOpen && (
                <Lightbox
                    mainSrc={postImages[photoIndex]}
                    enableZoom={false}
                    nextSrc={postImages[(photoIndex + 1) % postImages.length]}
                    prevSrc={postImages[(photoIndex + postImages.length - 1) % postImages.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + postImages.length - 1) % postImages.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % postImages.length)
                    }
                />
            )}
        </div>
        {props.post.category === EPostCategory.EXTERNAL && <div>
            <span style={{ color: '#0d6efd', cursor: 'pointer' }} onClick={() => {
                window.open(props.post.externalUrl, "_blank")
            }}>{props.post.urlLabel!}</span>
        </div>}
        {props.post.category === EPostCategory.INTERNAL && <div>
            {pollOption?.map((option: IPollOption, index) => (<div>
                {props.post.isPollMultiSelect === EBoolean.NO &&
                    <div className="up-reportoption" key={option._id + index}>
                        <div className="rt-radio rt-selectoptionstyle">
                            {pollClosed && pollTime > 0 && <>
                                <input type="radio" name="radio" onChange={(e) => voteOption(e, option, index)} id={option._id} value={votedOption?._id} checked={votedOption?._id === option?._id} />
                                <label htmlFor={option._id} className="up-paddingleft">{option?.option!}</label>
                            </>}
                            {/* {alreadyVoted && */}
                            <div className={pollClosed && pollTime > 0 ? 'up-selectedoptiongroup' : 'up-selectedoptiongroupposition'} key={option?._id}>
                                <div className={`up-selecedtoption ${option?.voters?.some(el => el?.votedBy?._id === currentUser?._id) ? 'up-topvoted' : ''}`}>
                                    {/* top voted add class (up-topvoted) in (up-selecedtoption) */}
                                    <em className="up-bgoption" style={{ width: ((option?.voters?.length / voteCount) * 100).toString() + '%' }}></em>
                                    {!pollClosed && <span className="up-pollclosed">{option?.option!}</span>}
                                    {props.post.showPollResults === EBoolean.YES && props.post.pollResultType === EPollResultType.NUMBER && <span>{option?.voters?.length}</span>}
                                    {props.post.showPollResults === EBoolean.YES && props.post.pollResultType === EPollResultType.PERCENTAGE && <span>{Math.round((option?.voters?.length / voteCount) * 100)}%</span>}
                                </div>
                            </div>
                            {/* } */}
                        </div>
                    </div>
                }
                {props.post.isPollMultiSelect === EBoolean.YES &&
                    <div className="up-reportoption" key={option._id + index}>
                        <div className="rt-selectoptionstyle">
                            {pollClosed && pollTime > 0 && <>
                                <input onChange={(event: any): any => handlePollSelection(event, option, index)} checked={multiOptions.some((el) => {
                                    return el._id === option._id
                                })} id={option._id} type="checkbox" name="poll-option" />
                                <label className="up-paddingleft" htmlFor={option._id}>{option?.option!}</label>
                            </>}
                            {/* {alreadyVoted && */}
                            <div className={pollClosed && pollTime > 0 ? 'up-selectedoptiongroup' : 'up-selectedoptiongroupposition'} key={option._id}>
                                <div className={`up-selecedtoption ${option?.voters?.some(el => el.votedBy._id === currentUser._id) ? 'up-topvoted' : ''}`}>
                                    {/* top voted add class (up-topvoted) in (up-selecedtoption) */}
                                    <em className="up-bgoption" style={{ width: ((option?.voters?.length / voteCount) * 100).toString() + '%' }}></em>
                                    {!pollClosed && <span className="up-pollclosed">{option?.option!}</span>}
                                    {props.post.showPollResults === EBoolean.YES && props.post.pollResultType === EPollResultType.NUMBER && <span>{option?.voters?.length}</span>}
                                    {props.post.showPollResults === EBoolean.YES && props.post.pollResultType === EPollResultType.PERCENTAGE && <span>{Math.round((option?.voters?.length / voteCount) * 100)}%</span>}
                                </div>
                            </div>
                            {/* } */}
                        </div>
                    </div>
                }
                {/* {alreadyVoted && */}
                {/* <div className="up-selectedoptiongroup" key={option._id}>
                        <div className={`up-selecedtoption ${option.voters.some(el => el.votedBy._id === currentUser._id) ? 'up-topvoted' : ''}`}> */}
                {/* top voted add class (up-topvoted) in (up-selecedtoption) */}
                {/* <em className="up-bgoption" style={{ width: ((option?.voters?.length / voteCount) * 100).toString() + '%' }}>{option?.voters?.length}</em> */}
                {/* <span>{option?.option!}</span> */}
                {/* {alreadyVoted && props.post.showPollResults === EBoolean.YES && props.post.pollResultType === EPollResultType.NUMBER && <em className="up-bgoption" style={{ width: ((option?.voters?.length / voteCount) * 100).toString() + '%' }}>{option?.voters?.length}</em>}
                            {alreadyVoted && props.post.showPollResults === EBoolean.YES && props.post.pollResultType === EPollResultType.PERCENTAGE && <em className="up-bgoption" style={{ width: ((option?.voters?.length / voteCount) * 100).toString() + '%' }}>{(option?.voters?.length / voteCount) * 100}</em>}
                        </div>
                    </div> */}
                {/* } */}

            </div>))}
            {pollTime > 0 && props.post.allowCustomPollOption === EBoolean.YES && props.post.pollType === EPollType.TEXT && <form onSubmit={textSubmit(textOnSubmit)}>
                <div className="form-group mt-3">
                    <div className=" col-lg-6 col-md-8 col-sm-12">
                        <input type="text"{...textRegister(`optionValue`)} className="form-control" placeholder={t('enterOption')} />
                    </div>
                </div>
                <button type="submit" className="up-btn">{t('addOption')}</button>
            </form>}
            {pollTime > 0 && props.post.allowCustomPollOption === EBoolean.YES && props.post.pollType === EPollType.DATE_TIME && <form onSubmit={dateSubmit(dateOnSubmit)}>
                <div className="form-group up-setaligndatetime">
                    <input id="value" type="date" {...dateRegister(`date`)} className="form-control" placeholder={t('enterOption')} />
                    <input id="startTime" {...dateRegister(`startTime`)} type="time" className="form-control" placeholder={t('startTime')} />
                    <input id="endTime" {...dateRegister(`endTime`)} type="time" className="form-control" placeholder={t('endTime')} />
                </div>
                <button type="submit" className="up-btn">{t('addOption')}</button>
            </form>}
            {pollTime === 0 && currentUser._id == props.post.creator._id &&
                <button className="up-btn" onClick={() => isPublic ? pleaseLogin() : setVotingDetail(true)}>{t('viewResult')}</button>
            }
            {pollTime > 0 &&
                <div onClick={() => currentUser._id == props.post.creator._id ?
                     (  isPublic ? pleaseLogin() : setVotingDetail(true) )
                    : ''}>{voteCount + ' votes'}</div>
            }
            <div className="up-pollclosedtext">{pollTime > 0 ? (pollTime < 60 ? pollTime + t('minutesRemaining') : Math.round(pollTime / 60) + t('hoursRemaining')) : t('pollClosed')}</div>
        </div>}
        {!isPublic && <div className="up-commentsarea">
            <div className="up-sectiontitle up-section;ikecomment">
                {props.post.allowComment !== 'NO' && <h3>{t('comments')}</h3>}
                {isLoggedIn && <a href="javascript:void(0);" onClick={() => likePost()} className="up-btnlikeunlike">
                    {props.post?.likes.includes(currentUser._id) ?
                        <img src={images.Like} alt="" />
                        // <i className="icon-coin-yen"></i>
                        :
                        <img src={images.UnLike} alt="" />
                        // <i className="icon-phone"></i>

                    }
                </a>}

                <div className="up-sharecommentholder up-labelinlineparent" >
                    {/* TODO */}
                    {/* onClick={() => likePost('NO')} Unlike onClick */}
                    {/* {props?.post?.likes?.includes(currentUser._id) && <label className="up-btncomments" style={{ cursor: 'pointer' }} >{props.post?.likes?.length} Likes</label>} */}
                    {/* {props.post?.isPinned === 'YES' && <span onClick={markPostAsSeen}>Mark as read</span>} */}
                    <label className="up-btncomments" style={{ cursor: 'pointer' }} onClick={() => isPublic ? pleaseLogin() :  setViewDetails(true)}>{props.post?.views?.length} {props.post?.views?.length == 1 ? t('view') : t('views')} </label>
                    <label className="up-btncomments" style={{ cursor: 'pointer' }} onClick={() => isPublic ? pleaseLogin() : setLikeDetails(true)}>{props.post?.likes?.length} {props.post?.likes?.length == 1 ? t('like') : t('likes')} </label>
                    {props.post.allowComment !== 'NO' && <a href="javascript:void(0);" className="up-btncomments">{commentsPaginationInfo?.totalRecords} {t('comments')}</a>}
                    {isLoggedIn && props.fromDashboard && props?.post?.allowShare !== 'NO' && myRole?.isMember && myRole?.role?.policies?.includes(EPolicy.CAN_SHARE_POSTS) && <Dropdown className="up-themedropdown up-sharepostdropdownarea up-reactdropdown">
                        <Dropdown.Toggle id="dropdown-basic" className="up-sahredropdowniconopen">
                            <img src={images.ShareIcon} alt="Share Icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="up-themedropdownmenu up-dropdownsharepost">
                            <Dropdown.Item onClick={() => props.setShareOrganizationPost(props.post)} >{t('shareInOrganization')}</Dropdown.Item>
                            <Dropdown.Item onClick={() => props.setShareInUserGroupsPost(props.post)} >{t('shareInUserGroup')}</Dropdown.Item>
                            {isLoggedIn && <FacebookShareButton className="up-sharefacebokkicon" quote={""} url={process.env.REACT_APP_FE_BASE_URL + '/post-detail/' + props.post._id} hashtag={"#UP2D8"}>
                                {t('shareOnFacebook')}
                            </FacebookShareButton>}
                        </Dropdown.Menu>
                    </Dropdown>}
                </div>
            </div>
            <ul className="up-comments">
                {postComments && myRole.isMember && myRole?.role?.policies.includes(EPolicy.CAN_VIEW_COMMENTS) && postComments.map((comment: IPostComment, index: number) => {
                    return <li key={comment._id}>
                        <div className="up-comment">
                            <figure className="up-avatar">
                                <img style={{ cursor: 'pointer' }} onClick={() => openProfile(comment.creator._id)} src={comment.creator?.avatar ?? images.UserPlaceholder} alt="Avatar Image" />
                            </figure>
                            <div className="up-commentscontent">
                                <h4 style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => openProfile(comment?.creator?._id)}>{comment?.creator?.name} <time>{moment(comment?.createdAt).fromNow()} </time></h4>
                                <div className="up-description">
                                    <p>{constructMessage(comment.content, comment.tags!)}</p>
                                </div>
                            </div>
                            {(comment.creator._id == currentUser._id || myRole?.role?.policies.includes(EPolicy.CAN_DELETE_COMMENTS) || props.fromAdmin)  &&
                                <Dropdown className="up-themedropdown up-dropdowncommentpopup">
                                    <Dropdown.Toggle id="dropdown-basic">
                                        <i className="icon-menu-icon"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="up-themedropdownmenu">
                                        <Dropdown.Item onClick={() => deleteComment(comment, index)} >{t('deleteComment')}</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                        </div>
                    </li>
                })}
            </ul>
            {/* {isLoggedIn && <FacebookShareButton quote={""} url={process.env.REACT_APP_FE_BASE_URL + '/post-detail/' + props.post._id} hashtag={"#UP2D8"}>
                <FacebookIcon size={48} round /> Facebook Share
            </FacebookShareButton>} */}
            {(commentsPaginationInfo?.totalRecords !== postComments?.length) && <span onClick={() => getPostComments(props.post._id, (commentsPaginationInfo?.currentPage! + 1))}>{t('showMoreComments')}</span>}
            {isLoggedIn && (props.post.allowComment !== 'NO') && myRole.isMember && myRole?.role?.policies.includes(EPolicy.CAN_ADD_COMMENTS) && <form className="up-formtheme up-formwritemessage" onSubmit={handleSubmit(onSubmit)}>
                <fieldset>
                    <div className="form-group">
                        <figure className="up-ownimage">
                            <img src={currentUser?.avatar ?? images.UserPlaceholder} alt="Own Image" />
                        </figure>
                        {/* <textarea {...register("content")} name="content" placeholder="Write your text here..." /> */}
                        <MentionsInput
                  placeholder={t('writeHere')}
                  className="up-testclassvtwo"
                  style={mentionsInputStyle}
                  value={content}
                  onChange={messageOnChangeHandler}
                  
                >
                  <Mention
                  trigger="@"
                  className="up-testclasscontent"
                  data={viewMentionDataHandler}
                />
                </MentionsInput>
                        {/* <small className="form-text text-muted">
                            {errors.content?.message}
                        </small> */}
                        {/* <button className="up-btn" type="submit">Post Comment</button> */}
                        <button 
                        className="up-btn"
                        type="button"
                        disabled={content.length == 0 || isSubmit}
                        onClick={() => onSubmit({content})}
                        >{t('postComment')}</button>
                    </div>
                </fieldset>
            </form>}
            {votingDetail && <VotingDetailModal closeModal={closeVotingDetailModal} id={props.post._id} post={props.post} />}
            {displayToDetail && <DisplayToDetailModal closeModal={closeDisplayToDetailModal} id={props.post._id} title={props.post.eWhichDisplayTo} />}
            {likeDetails && <UserDetailModal closeModal={closeLikeDetailModal} id={props.post._id} showLike={true} />}
            {viewDetails && <UserDetailModal closeModal={closeViewDetailModal} id={props.post._id} showLike={false} />}
        </div>}
    </div>
}
