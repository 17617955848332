import {ICreateGroupModalProps} from "../../organisations/createorganisation/interfaces/create-event-modal.interface";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import {images} from "../../../assets/images";
import {Dropdown} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {createGroupSchema} from "../../organisations/createorganisation/validations/create-group.validation";
import {ICreateGroup} from "../../organisations/createorganisation/interfaces/create-group.interface";
import {Toast} from "../../../utils/Toast";
import {S3} from "aws-sdk";
import {AWS_S3_BUCKET, s3} from "../../../config/aws.config";
import {groupService} from "../../../services/group.service";
import {
    IOrganizationRequestedMembers
} from "../../organisations/createorganisation/interfaces/organization-requested-members.interface";
import {useSelector} from "react-redux";
import {getUser} from "../../auth/slice/user.selector";
import {organisationService} from "../../../services/organisation.service";
import {changeRoleSchema} from "../../organisations/createorganisation/validations/change-role.validation";
import {useHistory} from "react-router";
import {FullPageLoader} from "../../../utils/FullPageLoader";
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";

export const CreateGroupModal: React.FC<ICreateGroupModalProps> = (props) => {
    const [createGroupModal, setCreateGroupModal] = useState(true);
    const [selectedGroupMembers, setSelectedGroupMembers] = useState<string[]>()
    const [groupCreationFile, setGroupCreationFile] = useState<any>()
    const [groupFilePreview, setGroupFilePreview] = useState<any>()
    const [previewSelectedGroupMembers, setPreviewSelectedGroupMembers] = useState<any[]>([])
    const [removeMembersFromParent, setRemoveMembersFromParent] = useState<any[]>([])
    const [isParentGroup, setIsParentGroup] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [selectedParentGroup, setSelectedParentGroup] = useState();
    const [groupMembers, setGroupMembers] = useState<IOrganizationRequestedMembers[]>([]);
    const [allParentOrganizations, setAllParentOrganizations] = useState<any[]>([]);
    const [memberSelectedForRoleChange, setMemberSelectedForRoleChange] = useState<any>()
    const [showChangeRoleModal, setChangeRoleModal] = useState(false);
    const { t } = useTranslation('', { keyPrefix: 'CreateGroupModal' });

    const currentUser = useSelector(getUser);
    const history = useHistory();

    const {
        register: createGroupRegister,
        handleSubmit: createGroupSubmit,
        formState: {errors: createGroupErrors},
        reset: createGroupReset,
        setValue: setEditGroupValues
    } = useForm({
        resolver: yupResolver(createGroupSchema)
    });
    const {
        register: changeRoleRegister,
        handleSubmit: changeRoleSubmit,
        formState: {errors: changeRoleErrors},
        reset: changeRoleReset,
        getValues,
        setValue: setChangeRoleValues
    } = useForm({
        resolver: yupResolver(changeRoleSchema)
    });
    useEffect(() => {
        changeRoleRegister('admin')
    }, [changeRoleRegister])
    useEffect(() => {
        if (!groupCreationFile) {
            setGroupFilePreview(undefined)
            return
        }
        const objectUrl: any = URL.createObjectURL(groupCreationFile)
        setGroupFilePreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)
    }, [groupCreationFile])
    useEffect(() => {
        getParentOrganizations(' ')
        // getOrganizationMembers();
        if (props.isEdit) {
            const group = props.selectedGroup;
            if (group.parentGroup) {
                setEditGroupValues('isParentGroup', 'yes')
                getParentGroupMembers(props.selectedGroup.parentGroup._id)
                setIsParentGroup(true);
            } else {
                setEditGroupValues('isParentGroup', 'no')
                setIsParentGroup(false);
            }
            setEditGroupValues('name', group.name);
            setEditGroupValues('startDate', group.startDate.split('T')[0]);
            if (group.endDate) {
                setEditGroupValues('endDate', group.endDate.split('T')[0]);
            }
            setEditGroupValues('canMemberChat', group.canMemberChat);
            if (group?.avatar) {
                setGroupFilePreview(group?.avatar);
            }
            let temp = [];
            for (const mins of group.admins) {
                mins.isAdmin = true;
                temp.push(mins)
            }
            for (const mins of group.members) {
                mins.isAdmin = false;
                temp.push(mins)
            }
            setPreviewSelectedGroupMembers(temp)
        }
    }, [])

    const getOrganizationMembers = async () => {
        const res: any = await organisationService.getOrganizationMembers(props.id, 1, '', 1000 );
        if (res.statusCode == 200) {
            const tempAdmins: IOrganizationRequestedMembers[] = [];
            let editMembers = [];
            if (props.isEdit) {
                for (const mins of props.selectedGroup.admins) {
                    mins.isAdmin = true;
                    editMembers.push(mins)
                }
                for (const mins of props.selectedGroup.members) {
                    mins.isAdmin = false;
                    editMembers.push(mins)
                }
            }
            for (const record of res.payload.organizationMembers.records) {
                switch (record.role.role) {
                    case 'Admin':
                        record.isAdmin = true;
                        record.value = record.member._id;
                        record.label = record.member.name;
                        tempAdmins.push(record)
                        if (props.isEdit){
                            for (let memeber of editMembers) {
                                if (record.member._id === memeber.member._id){
                                    memeber.role = record.role
                                }
                            }
                        }
                        break;
                }
            }
            setPreviewSelectedGroupMembers(props.isEdit? editMembers: tempAdmins)
            setIsloading(false);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }

    const getParentOrganizations = async (inputValue: string) => {
        const res: any = await groupService.getOrganizationGroups(props.id, 1, inputValue, 1000);
        if (res.statusCode == 200) {
            const tempArray: any[] = [];
            for (let i = 0; i < res.payload.groups.records.length; i++) {
                tempArray.push({
                    label: res.payload.groups.records[i].name,
                    value: res.payload.groups.records[i]._id
                })
            }
            setAllParentOrganizations(tempArray)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }

    const createOrganizationGroupSubmit = async (data: ICreateGroup) => {
        setIsloading(true);
        if (previewSelectedGroupMembers == null || previewSelectedGroupMembers.length === 0) {
            Toast.fire({
                icon: 'warning',
                title: t('pleaseSelectMemberFirst')
            })
            setIsloading(false);
            return;
        } else {
            const admins = previewSelectedGroupMembers.filter(item => item.isAdmin == true);
            const members = previewSelectedGroupMembers.filter(item => item.isAdmin == false);
            if (admins == null || admins.length == 0) {
                Toast.fire({
                    icon: 'warning',
                    title: t('pleaseSelectAdminFirst')
                })
                setIsloading(false);
                return
            }
            const tempMembers: any = [];
            for (const member of members) {
                tempMembers.push(member._id)
            }
            data.members = [...tempMembers];
            const tempAdmin: string[] = [];
            for (const adm of admins) {
                tempAdmin.push(adm._id)
            }
            data.admins = tempAdmin;
        }
        if (selectedParentGroup || data.isParentGroup == 'yes') {
            data.parentGroup = selectedParentGroup ?? props.selectedGroup.parentGroup._id
        }
        data.organization = props.id;
        if (groupCreationFile) {
            const params: S3.PutObjectRequest = {
                Body: groupCreationFile,
                Bucket: AWS_S3_BUCKET!,
                Key: `${parseInt(
                    (new Date().getTime() / 1000).toFixed(0)
                )}-${groupCreationFile.name}`,
                ContentType: groupCreationFile.type,
                ACL: "public-read",
            };
            const uploadResult: any = await s3.upload(params).promise();
            data.avatar = uploadResult.Location
        }

        if (props.isEdit) {
            const res: any = await groupService.updateGroup(props.selectedGroup!._id, data);
            if (res.statusCode == 201) {
                if (removeMembersFromParent.length) {
                    const memberIds = [];
                    const adminIds = [];
                    for (const item of removeMembersFromParent) {
                        if (item.isAdmin) {
                            adminIds.push(item._id)
                        } else {
                            memberIds.push(item._id)
                        }
                    }
                    const removeData = {
                        group: props.selectedGroup._id,
                        memberIds: memberIds,
                        adminIds: adminIds
                    }
                    await groupService.removeMembersFromParentGroup(removeData);
                }
                props.closeModal(true)
                setPreviewSelectedGroupMembers([])
                setRemoveMembersFromParent([])
                setSelectedGroupMembers(undefined);
                setGroupCreationFile(undefined);
                setGroupFilePreview(undefined)
                setIsParentGroup(false);
                setIsloading(false);
                createGroupReset({name: '', startDate: '', endDate: ''});
                Toast.fire({
                    icon: 'success',
                    title: res.message
                })

            } else {
                Toast.fire({
                    icon: 'warning',
                    title: res.message
                })
            }
        } else {
            const res: any = await groupService.createGroup(data);
            if (res.statusCode === 201) {
                props.closeModal(true)
                setPreviewSelectedGroupMembers([])
                setSelectedGroupMembers(undefined);
                setGroupCreationFile(undefined);
                setGroupFilePreview(undefined)
                setIsParentGroup(false);
                createGroupReset({name: '', startDate: '', endDate: ''});
                Toast.fire({
                    icon: 'success',
                    title: res.message
                })
                setIsloading(false);
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: res.message
                })
            }
        }

    }
    const getParentGroupMembers = async (id: string) => {
        const res: any = await groupService.getGroupMembers(id);
        if (res.statusCode == 200) {
            const dummyMembers: any = [...res.payload.admins, ...res.payload.members]
            for (const record of dummyMembers) {
                record.label = record.member.name;
                record.value = record.member._id;
            }
            setGroupMembers([...dummyMembers]);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const onChangeGroupParent = async (e: any) => {
        if (e) {
            const res: any = await groupService.getGroupMembers(e.value);
            if (res.statusCode == 200) {
                const dummyMembers: any = [...res.payload.admins, ...res.payload.members]
                for (const record of dummyMembers) {
                    record.label = record.member.name;
                    record.value = record.member._id;
                    if (record.value == currentUser._id) {
                        record.isAdmin = true;
                        record.added = true;
                    } else {
                        record.isAdmin = false;
                        record.added = false;
                    }
                }
                setGroupMembers([...dummyMembers]);
                setSelectedParentGroup(e.value)
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: res.message
                })
            }
        }
    }

    const groupParentChange = (event: any) => {
        if (event.target.value == 'yes') {
            setIsParentGroup(true);
        } else {
            setIsParentGroup(false);
        }
    }
    const onChangeGroupMemberSearch = (e: any) => {
            e.isAdmin = false;
            if (previewSelectedGroupMembers) {
                const temp = previewSelectedGroupMembers.find(pre => pre.member._id == e.member._id)
                if (!temp) {
                    setPreviewSelectedGroupMembers([...previewSelectedGroupMembers, e])
                }
            } else {
                setPreviewSelectedGroupMembers([e])
            }
    }

    const closeGroupCreationModal = () => {
        props.closeModal(false)
        setSelectedGroupMembers(undefined);
        setGroupCreationFile(undefined);
        setGroupFilePreview(undefined)
        setPreviewSelectedGroupMembers([])
        setIsParentGroup(false);
        setCreateGroupModal(false);
        createGroupReset({name: '', startDate: '', endDate: ''});
    }
    const userGroupSearchOptions = async (inputValue: string) => {
        const res: any = await organisationService.getOrganizationMembers(props.id, 1, inputValue, 10);
        if (res.statusCode == 200) {
            const tempArray: any[] = [];
            for (let i = 0; i < res.payload.organizationMembers.records.length; i++) {
                tempArray.push({
                    label: res.payload.organizationMembers.records[i].member.name,
                    value: res.payload.organizationMembers.records[i].member._id,
                    ...res.payload.organizationMembers.records[i]
                })
            }
            return tempArray;
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const removeSelectedUserFromGroup = (index: number) => {
        const isAdmin =  previewSelectedGroupMembers[index].isAdmin? 'Admin': 'Member';
        if (props.selectedGroup.childGroups.length == 0)
        {
            const member: any =  previewSelectedGroupMembers?.splice(index, 1);
            if (isParentGroup) {
                Swal.fire({
                    title: `Remove ${isAdmin}`,
                    text: `Do you want to remove this ${isAdmin} from all parent groups as well?`,
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                }).then(async (result: any) => {
                    if (result) {
                        if (result.isConfirmed) {
                            setRemoveMembersFromParent([...removeMembersFromParent, ...member])
                            setPreviewSelectedGroupMembers([...previewSelectedGroupMembers!])
                        }else{
                            setPreviewSelectedGroupMembers([...previewSelectedGroupMembers!])
                        }
                    }
                })
            } else {
                setPreviewSelectedGroupMembers([...previewSelectedGroupMembers!])
            }
        } else {
            Swal.fire({
                title: `Remove group member - 1/2`,
                text: `Removing this ${isAdmin} will remove them from all child groups as well`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel'
            }).then(async (result: any) => {
                if (result) {
                    if (result.isConfirmed) {
                        const member: any =  previewSelectedGroupMembers?.splice(index, 1);
                        if (isParentGroup)
                        {
                            Swal.fire({
                                title: `Remove group member - 2/2`,
                                text: `Do you want to remove this ${isAdmin} from all parent groups as well?`,
                                icon: 'question',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes',
                                cancelButtonText: 'No'
                            }).then(async (result: any) => {
                                if (result) {
                                    if (result.isConfirmed) {
                                        setRemoveMembersFromParent([...removeMembersFromParent, ...member])
                                        setPreviewSelectedGroupMembers([...previewSelectedGroupMembers!])
                                    }else{
                                        setPreviewSelectedGroupMembers([...previewSelectedGroupMembers!])
                                    }
                                }
                            })
                        }else{
                            setPreviewSelectedGroupMembers([...previewSelectedGroupMembers!])
                        }
                    }
                }
            })
        }
    }
    const openUserProfile = (member: any) => {
        if (currentUser._id === member.member._id) {
            // history.push(`/profile-page`)
            history.push({
                pathname: `/profile-page`,
                search: `${props.id}&${member._id}`
            });
        } else {
            if (props.myRole.role.role == 'Admin') {
                history.push({
                    pathname: `/memberprofilepage/${member.member._id}`,
                    search: `${props.id}&${member._id}`
                });
            } else {
                history.push(`/memberprofilepage/${member.member._id}`);
            }
        }
    }
    const groupCreationImageChange = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            setGroupCreationFile(e.target.files[0]);
        }
    };
    const openChangeRoleModal = (item: any) => {
        setMemberSelectedForRoleChange(item)
        if (item.isAdmin) {
            setChangeRoleValues('admin', false)
        } else {
            setChangeRoleValues('admin', true)
        }
        setChangeRoleModal(true)
    };
    const closeChangeRoleModal = () => {
        const values: any = getValues();
        if (values.admin == 1) {
            memberSelectedForRoleChange.isAdmin = false;
        } else {
            memberSelectedForRoleChange.isAdmin = true;
        }
        if (previewSelectedGroupMembers) {
            let tempIndex = previewSelectedGroupMembers!.findIndex((mem) => mem.member._id == memberSelectedForRoleChange.member._id)
            previewSelectedGroupMembers?.splice(tempIndex, 1, memberSelectedForRoleChange);
            setPreviewSelectedGroupMembers([...previewSelectedGroupMembers!])
        }
        setChangeRoleModal(false);
    }
    return <><Modal className="up-thememodal up-modalcreategroup" show={createGroupModal}
                    onHide={closeGroupCreationModal}>
        <Modal.Body className="up-modalbody">
            {isLoading && <FullPageLoader/>}
            <div className="up-modalcontentholder">
                <form className="up-formtheme up-formcreategroup"
                      onSubmit={createGroupSubmit(createOrganizationGroupSubmit)}>
                    <fieldset>
                        <div className="up-creategroupcontentholder">
                            <div className="up-creategroupcontent">
                                <div className="form-group">
                                    <label>{t('haveAnyParentUserGroup')}</label>
                                    <select {...createGroupRegister("isParentGroup")} onChange={groupParentChange}
                                            className="form-control">
                                        <option value="no">{t('no')}</option>
                                        <option value="yes">{t('yes')}</option>
                                    </select>
                                </div>
                                {isParentGroup && <div className="form-group">
                                    <label>{t('chooseParentGroup')}</label>
                                    <Select
                                        className="basic-single up-async-select"
                                        classNamePrefix="select"
                                        isClearable={false}
                                        defaultValue={props.isEdit ? {
                                            value: props.selectedGroup?.parentGroup?._id,
                                            label: props.selectedGroup?.parentGroup?.name
                                        } : ''}
                                        isSearchable={true}
                                        name="color"
                                        onChange={onChangeGroupParent}
                                        options={allParentOrganizations}
                                    />
                                </div>}
                                <div className="form-group">
                                    <label>{t('groupName')}</label>
                                    <input {...createGroupRegister("name")} type="text" name="name"
                                           className="form-control" placeholder={t('typeGroupName')} />
                                    <small id="emailHelp" className="form-text text-muted">
                                        {createGroupErrors.name?.message}
                                    </small>
                                </div>
                                <div className="form-group">
                                    <label>{t('addMembers')}</label>
                                    {/*{isParentGroup && <Select*/}
                                    {/*    className="basic-single up-async-select"*/}
                                    {/*    classNamePrefix="select"*/}
                                    {/*    isClearable={true}*/}
                                    {/*    isSearchable={true}*/}
                                    {/*    name="color"*/}
                                    {/*    onChange={onChangeGroupMemberSearch}*/}
                                    {/*    options={groupMembers}*/}
                                    {/*/>}*/}
                                    <AsyncSelect isClearable={true} className="up-async-select"
                                                                    onChange={onChangeGroupMemberSearch} cacheOptions
                                                                    defaultOptions
                                                                    loadOptions={userGroupSearchOptions}/>

                                    <div className="up-membersarea">
                                        {previewSelectedGroupMembers && previewSelectedGroupMembers.map((member: any, index: number) => {
                                            return <div key={member._id} className="up-member">
                                                <figure className="up-memberimg">
                                                    <img src={member.member?.avatar! ?? images.UserPlaceholder}
                                                         alt="Member Image"/>
                                                </figure>
                                                <div className="up-membername">
                                                    <h4>{member.member.name} {member.isAdmin &&
                                                        <span>({t('groupAdmin')})</span>} {!member.isAdmin &&
                                                        <span>({t('groupMember')})</span>} </h4>
                                                    <Dropdown className="up-themedropdown">
                                                        <Dropdown.Toggle id="dropdown-basic">
                                                            <i className="icon-menu-icon"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="up-themedropdownmenu">
                                                            <Dropdown.Item href="javascript:void(0);"
                                                                           onClick={() => openChangeRoleModal(member)}>{t('changeRole')}</Dropdown.Item>
                                                            <Dropdown.Item href="javascript:void(0);"
                                                                           onClick={() => removeSelectedUserFromGroup(index)}>{t('remove')}</Dropdown.Item>
                                                            <Dropdown.Item href="javascript:void(0);"
                                                                           onClick={() => openUserProfile(member)}>{t('viewDetail')}</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        })}
                                        {(previewSelectedGroupMembers == undefined || previewSelectedGroupMembers.length == 0) &&
                                            <h4>{t('noMembersSelected')}</h4>}
                                    </div>
                                </div>
                            </div>
                            <div className="up-creategroupcontent">
                                <div className="up-gourpprofileimage">
                                    <figure className="up-groupprofileimg">
                                        <div className="up-uploadgroupimg">
                                            <input accept="image/*" type="file" onChange={groupCreationImageChange}
                                                   name="upload-profile-img" id="up-uploadgroupprofileimg"/>
                                            <label htmlFor="up-uploadgroupprofileimg"><i
                                                className="icon-camera"></i></label>
                                        </div>
                                        <img src={groupFilePreview ?? images.RestPlaceholder}
                                             alt="Group Profile Image"/>
                                    </figure>
                                </div>
                                <div className="up-allowuertochat">
                                    <em>{t('allowGroupChat')}</em>
                                    <span className="up-select">
                                                <select {...createGroupRegister("canMemberChat")}>
                                                    <option value="YES">{t('yes')}</option>
                                                    <option value="NO">{t('no')}</option>
                                                </select>
                                            </span>
                                </div>
                                <div className="form-group" style={{marginTop: "8px"}}>
                                    <label>{t('startDate')}</label>
                                    <input type="date" {...createGroupRegister("startDate")} name="startDate"
                                           className="form-control" placeholder={t('startDate')} />
                                    <small id="emailHelp" className="form-text text-muted">
                                        {createGroupErrors.startDate?.message}
                                    </small>
                                </div>
                                <div className="form-group">
                                    <label>{t('endDate')}</label>
                                    <input type="date" {...createGroupRegister("endDate")} name="endDate"
                                           className="form-control" placeholder={t('endDate')} />
                                    <small id="emailHelp" className="form-text text-muted">
                                        {createGroupErrors.endDate?.message}
                                    </small>
                                </div>
                                <div className="up-formbuttonsarea">
                                    <button type="submit"
                                            className="up-btn up-btnwithloader">{props.isEdit ? t('updateToSaveChanges') : t('create')} {isLoading &&
                                        <div className="lds-dual-ring"></div>}</button>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </Modal.Body>
    </Modal>
        {showChangeRoleModal &&
            <Modal className="up-thememodal up-modalreport" show={showChangeRoleModal} onHide={closeChangeRoleModal}>
                <Modal.Header className="up-modalheader" closeButton>
                    <Modal.Title>{t('changeRole')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="up-modalbody">
                    <div className="up-modalcontentholder">
                        <form className="up-formtheme up-formreport">
                            <fieldset>
                                <ul className="up-reportoptions">
                                    <li>
                                        <div className="up-reportoption">
                                            <span>{t('groupAdmin')}</span>
                                            <div className="up-radio">
                                                <input {...changeRoleRegister("admin")} value="0" type="radio"
                                                       name="admin"
                                                       id="up-optiontwo"/>
                                                <label htmlFor="up-optiontwo"></label>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="up-reportoption">
                                            <span>{t('groupMember')}</span>
                                            <div className="up-radio">
                                                <input {...changeRoleRegister("admin")} value='1' type="radio"
                                                       name="admin"
                                                       id="up-optionthree"/>
                                                <label htmlFor="up-optionthree"></label>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </fieldset>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>}
    </>

}