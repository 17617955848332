import {useEffect, useState} from "react";
import {images} from "../../../../assets/images";
import {AppLayout} from "../../../../components/layouts/AppLayout";
import {ThemeCalendar} from "../../../themecomponents/ThemeCalendar";
import {IPaginationInfo} from "../../../../interfaces/pagination-info.interface";
import {postService} from "../../../../services/post.service";
import {IPost} from "../../../organisations/createorganisation/interfaces/post.interface";
import {Toast} from "../../../../utils/Toast";
import InfiniteScroll from 'react-infinite-scroll-component';
import {OrganizationPost} from "../../../../components/Post/Post";
import Swal from "sweetalert2";
import {IOrganization} from "../../../organisations/createorganisation/interfaces/organisation.interface";
import {useSelector} from "react-redux";
import {organisationService} from "../../../../services/organisation.service";
import {getUser} from "../../../auth/slice/user.selector";
import {SharePostOrganizationModal} from "../../../ownorganisation/components/SharePostModal";
import {CreatePostModall} from "../../../ownorganisation/components/CreatePostModal";
import {ReportPostModal} from "../../../ownorganisation/components/ReportPostModal";
import {FullPageLoader} from "../../../../utils/FullPageLoader";
import {IGroup} from "../../../organisations/createorganisation/interfaces/group.interface";
import {groupService} from "../../../../services/group.service";
import {SharePostInUserGroupModal} from "../../../ownorganisation/components/SharePostInUserGroupsModal";
import {useTranslation} from "react-i18next";
import Carousel from "react-material-ui-carousel";
import Lightbox from "react-image-lightbox";

export const HomePage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [sharePostModal, setSharePostModal] = useState(false);
    const [sharePostInUserGroupsModal, setSharePostInUserGroupsModal] = useState(false);
    const [postToBeSharedInOrganization, setPostToBeSharedInOrganization] = useState<IPost>();
    const [userGroups, setUserGroups] = useState<IGroup[]>([]);
    const [timelinePosts, setTimelinePosts] = useState<IPost[]>([]);
    const [pixeeImages, setPixeeImages] = useState<any[]>([]);
    const [paginationInfoPosts, setPaginationInfoPosts] = useState<IPaginationInfo>();
    const [pixeePaginationInfo, setPixeePaginationInfo] = useState<IPaginationInfo>();
    const [postToBeShared, setPostToBeShared] = useState<IPost>();
    const [selectedEditPost, setSelectedEditPost] = useState<IPost>();
    const [isEditPost, setIsEditPost] = useState(false);
    const [showReportPostModal, setShowReportPostModal] = useState(false)
    const [createPostModal, setCreatePostModal] = useState(false);
    const [userOrganizations, setUserOrganization] = useState<IOrganization[]>();
    const currentUser = useSelector(getUser);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const { t } = useTranslation('', { keyPrefix: 'HomePage' });

    useEffect(() => {
        getTimelinePosts(1)
        getPixeeImages(1);
    }, [])

    const getTimelinePosts = async (page: number) => {
        const res: any = await postService.getHomePosts(page);
        if (res.statusCode == 200) {
            setIsLoading(false);
            setPaginationInfoPosts(res.payload.posts.paginationInfo)
            if (timelinePosts) {
                setTimelinePosts([...timelinePosts, ...res.payload.posts.records])
            } else {
                setTimelinePosts([...res.payload.posts.records])
            }
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const getPixeeImages = async (page: number) => {
        const res: any = await postService.getPixeeImages(page);
        if (res.statusCode == 200) {
            setIsLoading(false);
            setPixeePaginationInfo(res.payload.screens.paginationInfo)
            if (page !== 1) {
                setPixeeImages([...pixeeImages, ...res.payload.screens.records])
            } else {
                setPixeeImages([...res.payload.screens.records])
            }
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const editPost = (selectedPost: IPost) => {
        setSelectedEditPost(selectedPost);
        setIsEditPost(true);
        setCreatePostModal(true);
    }
    const sharePost = async (selectedPost: IPost) => {
        setPostToBeShared(selectedPost);
        const res: any = await organisationService.getUserOrganizations(currentUser._id)
        if (res.statusCode === 200) {
            const temp = [{ _id: 0, name: 'Select Organisation.' }, ...res.payload.organizations]
            setUserOrganization(temp)
            setSharePostModal(true);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const shareInUserGroupsPost = async (selectedPost: IPost) => {
        setPostToBeSharedInOrganization(selectedPost);
        const res: any = await groupService.getUserGroups(1, '', selectedPost.organization?._id)
        if (res.statusCode === 200) {
            const temp = [{ _id: 0, name: 'Select User Group' }, ...res.payload.groups.records]
            setUserGroups(temp)
            setSharePostInUserGroupsModal(true);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const deletePost = async (selectedPost: IPost) => {
        Swal.fire({
            title: t('areYouSure'),
            text: t('youWontRevert'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('yesDeleteIt')
        }).then(async (result: any) => {
            if (result) {
                if (result.isConfirmed) {
                    const res: any = await postService.deletePost(selectedPost._id);
                    if (res.statusCode = 200) {
                        const tempIndex = timelinePosts!.findIndex((post: any) => post._id === selectedPost._id)
                        timelinePosts!.splice(tempIndex, 1);
                        setTimelinePosts([...timelinePosts!])
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: res.message
                        })
                    }
                }
            }
        })
    }
    const closeSharePostModal = (data: boolean) => {
        setSharePostModal(false);
        setSharePostInUserGroupsModal(false);
    }
    const handleClosePostModal = () => {
        setCreatePostModal(false);
        setIsEditPost(false);
        setSelectedEditPost(undefined)
    }
    const setPosts = (data: IPost[]) => {
        setTimelinePosts(data);
        setCreatePostModal(false);
    }
    const reportPost = (selectedPost: IPost) => {
        setSelectedEditPost(selectedPost);
        setShowReportPostModal(true);
    }
    const setLikePost = (postId: string, userId: string, status: string) => {

        const postIndex = timelinePosts?.findIndex((post) => post._id === postId);
        if (postIndex !== -1) {
            if (status === 'YES') {
                timelinePosts[postIndex].likes.push(currentUser._id);
            } else {
                const index = timelinePosts[postIndex].likes.findIndex((lik) => lik === userId);
                if (index !== -1) {
                    timelinePosts[postIndex].likes.splice(index, 1);
                }
            }
            setTimelinePosts([...timelinePosts])
        }
    }
    const closeReportPostModal = () => {
        setShowReportPostModal(false);
    }
    const removeFromList = (id:string) => {
        const index =  timelinePosts.findIndex(item => item._id === id)
        timelinePosts.splice(index,1)
        setTimelinePosts([...timelinePosts])
    }
    const openImages = (index: number) => {
        setPhotoIndex(index);
        setIsOpen(true);
    }
    return (
        <AppLayout>
            {isLoading && <FullPageLoader />}
            <div className="up-homepage">
                <div className="up-twocolumns">
                    <div className="up-postsholder">
                        {pixeeImages && pixeeImages.length > 0 && <Carousel
                            className='carousel-containor up-sldierhight'>
                            {
                                pixeeImages.map((item, i) => <img className='carousel-img' key={i + item._id}
                                                                  onClick={() => openImages(i)}
                                                                  src={item.media}/>)
                            }
                        </Carousel>}
                        {isOpen && (
                            <Lightbox
                                mainSrc={pixeeImages[photoIndex].media}
                                enableZoom={false}
                                nextSrc={pixeeImages[(photoIndex + 1) % pixeeImages.length]}
                                prevSrc={pixeeImages[(photoIndex + pixeeImages.length - 1) % pixeeImages.length]}
                                onCloseRequest={() => setIsOpen(false)}
                                onMovePrevRequest={() =>
                                    setPhotoIndex((photoIndex + pixeeImages.length - 1) % pixeeImages.length)
                                }
                                onMoveNextRequest={() =>
                                    setPhotoIndex((photoIndex + 1) % pixeeImages.length)
                                }
                            />
                        )}
                        <div className="up-postsarea">
                            {timelinePosts && <InfiniteScroll
                                dataLength={timelinePosts!.length} //This is important field to render the next data
                                next={() => getTimelinePosts((paginationInfoPosts?.currentPage! + 1))}
                                hasMore={timelinePosts!.length !== paginationInfoPosts?.totalRecords!}
                                loader={<h4>{t('loading')}</h4>}
                                endMessage={
                                    <p className="up-allpostseen" style={{ textAlign: 'center' }}>
                                        <b>{t('pleaseWait')}</b>
                                    </p>
                                }>
                                {timelinePosts && timelinePosts.map((item, index) => {
                                    return <OrganizationPost removeFromList={removeFromList} fromAdmin={false} public={false} fromDashboard={true} setLikePost={setLikePost} reportPost={reportPost} fromTimeline={true} key={item._id + index} post={item} showInOrganization={false} setEditPost={editPost} setShareOrganizationPost={sharePost} setShareInUserGroupsPost={shareInUserGroupsPost} setDeletePost={deletePost} />
                                })}
                            </InfiniteScroll>}
                            {/* <div className="up-post up-addpost">
                                <h4>ad</h4>
                            </div> */}
                            {createPostModal && <CreatePostModall id={selectedEditPost?.organization?._id!} organizationPosts={timelinePosts!} selectedEditPost={selectedEditPost!} isEditPost={isEditPost} setOrganizationPosts={setPosts} closeModal={handleClosePostModal} organizationRoles={[]} />}

                            {sharePostModal && <SharePostOrganizationModal postToBeShared={postToBeShared!} userOrganizations={userOrganizations!} closeModal={closeSharePostModal} />}
                            {sharePostInUserGroupsModal && <SharePostInUserGroupModal postToBeShared={postToBeSharedInOrganization!} userGroups={userGroups!} closeModal={closeSharePostModal} />}
                        </div>
                    </div>
                    <aside className="up-sidebar">
                        <div className="up-widget up-widgetcalendar">
                            <div className="up-widgethead">
                                <h3>{t('events')}</h3>
                            </div>
                            <div className="up-themecalendarholder">
                                <ThemeCalendar />
                            </div>
                        </div>
                        <div className="up-widget up-widgetadd">
                            {/* <h5>ad</h5> */}
                            <figure className="up-adimagholder">
                                <a href={'https://www.cnhindustrial.com/en-US/Careers'} target="_blank">
                                    <img src={images.adNl} alt="Add Image" />
                                </a>
                            </figure>
                        </div>
                        {/* <div className="up-widget up-widgetpublicupdates"> */}
                        {/* Small Loader Start */}
                        {/* <div className="at-fullloader at-smallloader">
                                <span className="loader"></span>
                            </div> */}
                        {/* Small Loader ENd */}
                        {/* <div className="up-widgethead">
                                <h3>Public Updates</h3>
                            </div>
                            <ul className="up-publicupdates">
                                <li>
                                    <div className="up-publicupdate">
                                        <h6>European Super League: Premier League's 'big six' agree to join new league</h6>
                                        <p>Jose Mourinho has been sacked by Tottenham after just 17 months in charge.....</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="up-publicupdate">
                                        <h6>Reasons why a European Super League would be great for football</h6>
                                        <p>Liverpool and Manchester United are among the clubs who have reportedly signed up ….</p>
                                    </div>
                                </li>
                            </ul>
                        </div> */}
                    </aside>
                </div>
            </div>
            {/* REPORT POST MODAL */}
            {showReportPostModal && <ReportPostModal closeModal={closeReportPostModal} />}
        </AppLayout>
    )
}