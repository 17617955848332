export const images = {
    UserPlaceholder : require('./images/user_placeholder.png').default,
    RestPlaceholder : require('./images/rest_placeholder.png').default,
    Logo: require('./images/logo.svg').default,
    LoginCircles: require('./images/login-circles.png').default,
    UserIcon: require('./images/user-icon.png').default,
    ManVectoImage: require('./images/man-vector.png').default,
    OrganiserCoverImage: require('./images/organiser/cover-img.jpg').default,
    OrganiserCompanyLogo: require('./images/organiser/organiser-logo.jpg').default,
    MenuIcon: require('./images/menu-icon.svg').default,
    UserProfileImage: require('./images/user-profile.jpg').default,
    UserImageTag: require('./images/icons/reward-icon.svg').default,
    MessageIcon: require('./images/icons/message-icon.svg').default,
    NotificationIcon: require('./images/icons/notification-bell.svg').default,
    UserOne: require('./images/users/user-1.jpg').default,
    UserTwo: require('./images/users/user-2.jpg').default,
    UserThree: require('./images/users/user-3.jpg').default,
    UserFour: require('./images/users/user-4.jpg').default,
    UserFive: require('./images/users/user-5.jpg').default,
    UserSix: require('./images/users/user-6.jpg').default,
    UserSeven: require('./images/users/user-7.jpg').default,
    InnerBannerOne: require('./images/banner/people-banner-img.png').default,
    BannerPatteren: require('./images/banner/patteren-img.png').default,
    PostPlaceHolder: require('./images/post/post-placeholder.jpg').default,
    ReactIcon: require('./images/icons/react-icon.svg').default,
    LikeIcon: require('./images/icons/like-icon.svg').default,
    LoveIcon: require('./images/icons/love-icon.svg').default,
    SadIcon: require('./images/icons/sad-icon.svg').default,
    SurpriseIcon: require('./images/icons/surprised-icon.svg').default,
    HappyIcon: require('./images/icons/happy-icon.svg').default,
    ShareIcon: require('./images/icons/share-icon.svg').default,

    FacebookIcon: require('./images/icons/facebook-icon.svg').default,
    InstagramIcon: require('./images/icons/instagram-icon.svg').default,
    PinterestIcon: require('./images/icons/pinterest-icon.svg').default,
    TwitterIcon: require('./images/icons/twitter-icon.svg').default,

    FaceBookIconTwo: require('./images/icons/new-icons/facebook.png').default,
    InstaGramIconTwo: require('./images/icons/new-icons/instagram.png').default,
    // InstaGramIconThree: require('./images/icons/new-icons/instagram.png').default,
    TwitterIconTwo: require('./images/icons/twitter-icon2.svg').default,
    TwitterIconThree: require('./images/icons/new-icons/twitter.png').default,
    PinterestIconTwo: require('./images/icons/pinterest-icon-2.svg').default,

    EventsImageOne: require('./images/calendar-events/img-01.jpg').default,
    EventsImageTwo: require('./images/calendar-events/img-02.jpg').default,
    EventsImageThree: require('./images/calendar-events/img-03.jpg').default,
    OrganisationBannerImage: require('./images/banner/team-banner-img.png').default,
    OrganisationProfileImage: require('./images/banner/profile-banner.jpg').default,

    MemberOne: require('./images/members/img-01.jpg').default,
    MemberTwo: require('./images/members/img-02.jpg').default,
    MemberThree: require('./images/members/img-03.jpg').default,
    MemberFour: require('./images/members/img-04.jpg').default,
    MemberFive: require('./images/members/img-05.jpg').default,

    MediaImgOne: require('./images/media/img-01.jpg').default,
    MediaImgTwo: require('./images/media/img-02.jpg').default,
    MediaImgThree: require('./images/media/img-03.jpg').default,

    EventLogoImgOne: require('./images/media/img-05.jpg').default,
    EventLogoImgTwo: require('./images/media/img-06.jpg').default,
    EventLogoImgThree: require('./images/media/img-07.jpg').default,
    CreateOrganisationImage: require('./images/organiser/create-organisation.png').default,
    CreateOrganisationImageRight: require('./images/organiser/team-amico.png').default,
    CreateOrganisationImageTwo: require('./images/organiser/create-organisation-step-two.png').default,
    UploadLogoPlaceHolder: require('./images/wuquest-logo.png').default,
    OwnOrganisationBanneImage: require('./images/banner/own-organisation-banner.jpg').default,
    OwnOrganisationLogo: require('./images/logo-2.jpg').default,
    ArrowDownIcon: require('./images/arrow-down.png').default,
    GroupProfileImage: require('./images/group-profile-img.jpg').default,
    FileIcon: require('./images/icons/file-icon.png').default,
    PlayIcon: require('./images/icons/play-icon.png').default,
    FolderIcon: require('./images/icons/folder-icon.png').default,
    PictureIcon: require('./images/icons/picture-icon.png').default,
    InfoIcon: require('./images/icons/info-icon.png').default,
    MediaImage: require('./images/media/img-09.jpg').default,
    /* Edit Profile Icons */
    UserIconProfile: require('./images/icons/new-icons/user-icon.png').default,
    UserIconEnvelope: require('./images/icons/new-icons/envelope-icon.png').default,
    UserIconWeb: require('./images/icons/new-icons/web-icon.png').default,
    UserIconCalendar: require('./images/icons/new-icons/calendar-icon.png').default,
    UserIconLock: require('./images/icons/new-icons/lock-icon.png').default,
    UserIconPhone: require('./images/icons/new-icons/phone-icon.png').default,
    UserIconLocation: require('./images/icons/new-icons/location-icon.png').default,
    UserIconPostCode: require('./images/icons/new-icons/postcode-icon.png').default,

    CalenderIconB: require('./images/icons/new-icons/calendar-icon-b.png').default,
    PhoneIconB: require('./images/icons/new-icons/phone-icon-b.png').default,
    EnvelopeIconB: require('./images/icons/new-icons/envelope-icon-b.png').default,
    LocationIconB: require('./images/icons/new-icons/location-icon-b.png').default,
    OrganisationNameIcon: require('./images/icons/new-icons/name-icon.png').default,
    // GlobeIcon: require('./images/icons/new-icons/globe-icon.png').default,
    AddImage: require('./images/add-image.jpg').default,
    Like: require('./images/like.png').default,
    UnLike: require('./images/unlike.png').default,

    newAd: require('./images/new_ad.jpg').default,
    adNl: require('./images/ad_nl.png').default,
    Veifed: require('./images/verifed.png').default,
    Org: require('./images/icons/new-icons/orgin.png').default,
    City: require('./images/icons/new-icons/city.png').default,
    Country: require('./images/icons/new-icons/world.png').default,
    Web: require('./images/icons/new-icons/web.png').default,
    Address: require('./images/icons/new-icons/address.png').default,
    Rau: require('./images/rau.jpg').default
    // new dashboard images
}